
import React, { useEffect } from 'react';
import AOS from 'aos';
import page_json from './config/config.json';
import ContactForm from './ContactForm';
import { Helmet } from 'react-helmet';

const textDataUrl = page_json.page_jsons.ItServices;
const textDataResponse = await fetch(textDataUrl);
const textData = await textDataResponse.json();

function ItServices() {
	useEffect(() => {
		AOS.init(); // Initialize AOS
	}, []);

	return (
		<div data-aos="fade-up">
			<Helmet>
                <meta name="description" content="Building and managing organizational excellence with all-encompassing IT Services" />
                <title>IT Services | Services | Engineersmind</title>
            </Helmet>
			<div className="container-fluid m-0 p-0 brd-crmb">
				<div className="container">
					<div className="row">
						<div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
							<nav aria-label="breadcrumb">
								<ol className="breadcrumb">
									<li className="breadcrumb-item"><a href="/">Home </a></li>
									<li className="breadcrumb-item"><a href="/Services/Digital-Transformation">Services</a></li>
									<li className="breadcrumb-item active" aria-current="page">Managed IT Services</li>
								</ol>
							</nav>
						</div>
					</div>
				</div>
			</div>

			<div className="container-fluid p-0">
				<div className="inner-banner">
					<img src={textData.layer1.img} className="d-block w-100" alt="banner-img" />
					<div className="inner-banner-text">
						<div className="container">
							<div className="row">
								<div className="col-lg-8 col-md-8 col-sm-8 col-xs-8">
									<h1 className="animate__animated animate__fadeInLeft">{textData.layer1.heading.text1}<br /> {textData.layer1.heading.text2}<br /> {textData.layer1.heading.text3}<span> {textData.layer1.heading.text4}</span></h1>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>

			<div className="container-fluid manufacture">
				<div className="container">
					<h1>{textData.layer2.heading}</h1>
					<div className="row">
						<div className="col-lg-4 col-md-12 col-sm-12 col-xs-12">
							<div className="data-text">
								<p>{textData.layer2.body}</p>
								<a href={textData.layer2.button.url}>{textData.layer2.button.text}<i className="fa-solid fa-arrow-right"></i></a>
							</div>
						</div>
						<div className="col-lg-8 col-md-12 col-sm-12 col-xs-12">
							<div className="manufacture-img">
								<img src={textData.layer2.img} className="img-fluid" alt="industry" />
							</div>
						</div>
					</div>
				</div>
			</div>

			<div className="container-fluid Resilient">
				<div className="container">
					<h1>{textData.layer3.heading}</h1>
					<div className="row">
						<div className="col-lg-6 col-md-6 col-sm-6 col-xs-6">
							<img src={textData.layer3.img} className="img-fluid" alt="Resilient_solutions" />
						</div>
						<div className="col-lg-6 col-md-6 col-sm-6 col-xs-6">
							<div id="carouselExample" className="carousel slide">
								<div className="carousel-inner">
								{
                                    textData.layer3.editable_details.map((item, index) => {
                                        if (index === 0 ) {
                                            return( 
                                                <div className={ "carousel-item carousel-item-".concat(String.toString(index + 1)).concat(" active") }>
                                                    <div className="row">
														<div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
															<div className="card-3">
																<h5>{item.serial_no}</h5>
																<p>{item.text}</p>
															</div>
														</div>
													</div>
                                                </div>)
                                            } else {
                                                return( 
                                                <div className={ "carousel-item carousel-item-".concat(String.toString(index + 1)) }>
                                                    <div className="row">
														<div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
															<div className="card-3">
																<h5>{item.serial_no}</h5>
																<p>{item.text}</p>
															</div>
														</div>
													</div>
                                                </div>
                                            )}
                                        })
                                        
                                }
								</div>
								<br/>
								<div className="control-btn-1">
									<button className="carousel-control-next" type="button" data-bs-target="#carouselExample" data-bs-slide="next">
										<i className="fa-solid fa-arrow-right"></i>
										<span className="carousel-control-next-icon visually-hidden" aria-hidden="true"></span>
										<span className="visually-hidden">{textData.layer3.button_next}</span>
									</button>
									<button className="carousel-control-prev" type="button" data-bs-target="#carouselExample" data-bs-slide="prev">
										<span className="carousel-control-prev-icon visually-hidden" aria-hidden="true"></span>
										<i className="fa-solid fa-arrow-left"></i>
										<span className="visually-hidden">{textData.layer3.button_previous}</span>
									</button>
								</div>
							</div>
						</div>
						<div className="col-lg-6 col-md-6 col-sm-6 col-xs-6"></div>
					</div>
				</div>
			</div>

			<div className="container-fluid Resilient">
				<div className="container">
					<h1>{textData.layer4.heading}</h1>
					<div className="row">
						<div className="col-lg-6 col-md-6 col-sm-6 col-xs-6">
							<div id="carouselExample1" className="carousel slide">
								<div className="carousel-inner">
								{
                                    textData.layer4.editable_details.map((item, index) => {
                                        if (index === 0 ) {
                                            return( 
                                                <div className={ "carousel-item carousel-item-".concat(String.toString(index + 1)).concat(" active") }>
                                                    <div className="row">
														<div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
															<div className="card-4">
																<h5>{item.serial_no}</h5>
																<p>{item.text}</p>
															</div>
														</div>
													</div>
                                                </div>)
                                            } else {
                                                return( 
                                                <div className={ "carousel-item carousel-item-".concat(String.toString(index + 1)) }>
                                                    <div className="row">
														<div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
															<div className="card-4">
																<h5>{item.serial_no}</h5>
																<p>{item.text}</p>
															</div>
														</div>
													</div>
                                                </div>
                                            )}
                                        })
                                        
                                }
								</div>
								<div className="control-btn-6">
									<button className="carousel-control-next" type="button" data-bs-target="#carouselExample1" data-bs-slide="next">
										<i className="fa-solid fa-arrow-right"></i>
										<span className="carousel-control-next-icon visually-hidden" aria-hidden="true"></span>
										<span className="visually-hidden">{textData.layer4.button_next}</span>
									</button>
									<button className="carousel-control-prev" type="button" data-bs-target="#carouselExample1" data-bs-slide="prev">
										<span className="carousel-control-prev-icon visually-hidden" aria-hidden="true"></span>
										<i className="fa-solid fa-arrow-left"></i>
										<span className="visually-hidden">{textData.layer4.button_previous}</span>
									</button>
								</div>
							</div>
						</div>
						<div className="col-lg-6 col-md-6 col-sm-6 col-xs-6">
							<img src={textData.layer4.img} className="img-fluid" alt="Managed Services" />
						</div>
					</div>
				</div>
			</div>

			<div className="container-fluid Resilient">
				<div className="container">
					<h1>{textData.layer5.heading}</h1>
					<div className="row">
						<div className="col-lg-6 col-md-6 col-sm-6 col-xs-6">
							<img src={textData.layer5.img} className="img-fluid" alt="Resilient_solutions" />
						</div>
						<div className="col-lg-6 col-md-6 col-sm-6 col-xs-6">
							<div className="card-1">
								<p>{textData.layer5.description.point1}</p>&nbsp;
								<p>{textData.layer5.description.point2}</p>
							</div>
						</div>
					</div>
				</div>
			</div>

			<div className="container-fluid services-home">
				<div className="container">
					<h1><span>{textData.layer6.heading}</span></h1>
					<p>{textData.layer6.body}</p>
					{/* <div className="more-1">
						<a href="/services">Explore <i className="fa-solid fa-arrow-right"></i></a>
					</div> */}
					<div className="row">
						<div className="col-lg-2 col-md-2 col-sm-12 col-xs-12"></div>
						<div className="col-lg-2 col-md-2 col-sm-12 col-xs-12">
							<div className="module-border-wrap">
								<div className="module">
									<a href={textData.layer6.details.data1.link} id="pdng">{textData.layer6.details.data1.label}</a>
								</div>
							</div>
						</div>
						<div className="col-lg-2 col-md-2 col-sm-12 col-xs-12">
							<div className="module-border-wrap">
								<div className="module">
									<a href={textData.layer6.details.data2.link} id="pdng">{textData.layer6.details.data2.label}</a>
								</div>
							</div>
						</div>
						<div className="col-lg-2 col-md-2 col-sm-12 col-xs-12">
							<div className="module-border-wrap">
								<div className="module">
									<a href={textData.layer6.details.data3.link} id="pdng">{textData.layer6.details.data3.label}</a>
								</div>
							</div>
						</div>
						<div className="col-lg-2 col-md-2 col-sm-12 col-xs-12">
							<div className="module-border-wrap">
								<div className="module">
									<a href={textData.layer6.details.data4.link} id="pdng">{textData.layer6.details.data4.label}</a>
								</div>
							</div>
						</div>
						<div className="col-lg-2 col-md-2 col-sm-12 col-xs-12"></div>
						<div className="col-lg-2 col-md-2 col-sm-12 col-xs-12"></div>
						<div className="col-lg-3 col-md-3 col-sm-12 col-xs-12">
							<div className="module-border-wrap">
								<div className="module">
									<a href={textData.layer6.details.data5.link} id="pdng">{textData.layer6.details.data5.label}</a>
								</div>
							</div>
						</div>
						<div className="col-lg-3 col-md-3 col-sm-12 col-xs-12">
							<div className="module-border-wrap">
								<div className="module">
									<a href={textData.layer6.details.data6.link} id="pdng">{textData.layer6.details.data6.label}</a>
								</div>
							</div>
						</div>
						<div className="col-lg-2 col-md-2 col-sm-12 col-xs-12">
							<div className="module-border-wrap">
								<div className="module">
									<a href={textData.layer6.details.data7.link} id="pdng">{textData.layer6.details.data7.label}</a>
								</div>
							</div>
						</div>
						<div className="col-lg-2 col-md-2 col-sm-12 col-xs-12"></div>
					</div>
				</div>
			</div>

			<div className="container-fluid trust">
				<div className="container trusted-img">
					<div className="row">
						<h1>{textData.layer7.heading}</h1>
						<div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
							<img src={textData.layer7.img} alt="logos" className="img-fluid" />

						</div>
					</div>
				</div>
			</div>

			<div className="container-fluid alliance">
				<div className="container">
					<div className="row">
						<div className="col-lg-7 col-md-6 col-sm-12 col-xs-12">
							<div className="heading">
								<h1><span>{textData.layer8.heading.text1}</span>{textData.layer8.heading.text2}<span>{textData.layer8.heading.text3}</span></h1>
							</div>
							<div className="more-2">
								<a href={textData.layer8.button.url}>{textData.layer8.button.text}<i className="fa-solid fa-arrow-right"></i></a>
							</div>
						</div>
						<div className="col-lg-5 col-md-6 col-sm-12 col-xs-12">
							<div className="gif">
								<img src={textData.layer8.button.img} alt="gif" className="img-fluid" />
							</div>
						</div>
					</div>
				</div>
			</div>
            <div className="container-fluid ContactForm">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                            <ContactForm />
                        </div>
                    </div>
                </div>
            </div>
            <div className="container-fluid tabg alliance">
				<div className="TA-bg">
					<div className="container">
						<div className="row">
							<h1>{textData.layer9.heading}</h1>
							<div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
								<img src={textData.layer9.img} alt="logos" className="img-fluid" />

							</div>
						</div>
					</div>
				</div>
			</div>

		</div>
	)
}

export default ItServices;