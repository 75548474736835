
import React, { useEffect, useState } from 'react';
import AOS from 'aos';
import page_json from './config/config.json';
import ContactForm from './ContactForm';
import { Helmet } from 'react-helmet';

//import textData from "../Jsons/SecurityCertificationsServices.json"; //needs to comment for prod
const textDataUrl = page_json.page_jsons.SecurityServices;
const textDataResponse = await fetch(textDataUrl);
const textData = await textDataResponse.json();  //needs to un-comment for prod

function SecurityServices() {
	useEffect(() => {
		AOS.init(); // Initialize AOS
	}, []);

	const [activePanel, setActivePanel] = useState(null);
	const togglePanel = (panelId) => {
		setActivePanel(activePanel === panelId ? null : panelId);
	};
	var left_index = 0;
	return (
		<div data-aos="fade-up">
			<Helmet>
                <meta name="description" content="Managed IT Security Services and Certifications" />
                <title>Security Services | Services | Engineersmind</title>
            </Helmet>
			<div className="container-fluid m-0 p-0 brd-crmb">
				<div className="container">
					<div className="row">
						<div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
							<nav aria-label="breadcrumb">
								<ol className="breadcrumb">
									<li className="breadcrumb-item"><a href="/">Home </a></li>
									<li className="breadcrumb-item"><a href="/Services/Digital-Transformation">Services </a></li>
									<li className="breadcrumb-item active" aria-current="page">Managed Security Services & Certifications</li>
								</ol>
							</nav>
						</div>
					</div>
				</div>
			</div>

			<div className="container-fluid p-0">
				<div className="inner-banner">
					<img src={textData.layer1.img} className="d-block w-100" alt="banner-img" />
					<div className="inner-banner-text">
						<div className="container">
							<div className="row">
								<div className="col-lg-8 col-md-8 col-sm-8 col-xs-8">
									<h1 className="animate__animated animate__fadeInLeft">{textData.layer1.heading.text1}<br /> <span>{textData.layer1.heading.text2}</span> {textData.layer1.heading.text3} <span>{textData.layer1.heading.text4}</span></h1>

								</div>
							</div>
						</div>
					</div>
				</div>
			</div>

			<div className="container-fluid certifications">
				<div className="container">
					<h1>{textData.layer2.heading} </h1>
					<div className="row">
						<div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
							<p>{textData.layer2.body.text1}</p>&nbsp;
							<p>{textData.layer2.body.text2}</p>
						</div>
					</div>
				</div>
			</div>

			<div className="container-fluid matrix-1">
				<div className="container">
					<h1>{textData.layer3.heading}</h1>
					<div className="row">
						<div className="col-lg-5 col-md-5 col-sm-5 col-xs-5">
							<div className="nav flex-column nav-pills nav-pills-custom" id="v-pills-tab" role="tablist" aria-orientation="vertical">
								<a className="nav-link active" id="unified" data-bs-toggle="pill" href="#UA" role="tab" aria-controls="v-pills-home" aria-selected="true">
									<span>{textData.layer3.detail.matrix1.heading1} <i className="fa-solid fa-arrow-right"></i></span>
								</a>
								<a className="nav-link" id="streamlined" data-bs-toggle="pill" href="#SDI" role="tab" aria-controls="v-pills-profile" aria-selected="false">
									<span>{textData.layer3.detail.matrix2.heading1}  <i className="fa-solid fa-arrow-right"></i></span>
								</a>
								<a className="nav-link" id="scalabale" data-bs-toggle="pill" href="#SDW" role="tab" aria-controls="v-pills-messages" aria-selected="false">
									<span>{textData.layer3.detail.matrix3.heading1}  <i className="fa-solid fa-arrow-right"></i></span>
								</a>
								<a className="nav-link" id="visualization" data-bs-toggle="pill" href="#DEV" role="tab" aria-controls="v-pills-settings" aria-selected="false">
									<span>{textData.layer3.detail.matrix4.heading1}  <i className="fa-solid fa-arrow-right"></i></span>
								</a>
								<a className="nav-link" id="advanced" data-bs-toggle="pill" href="#AAML" role="tab" aria-controls="v-pills-settings" aria-selected="false">
									<span>{textData.layer3.detail.matrix5.heading1}  <i className="fa-solid fa-arrow-right"></i></span>
								</a>
							</div>
						</div>
						<div className="col-lg-7 col-md-7 col-sm-7 col-xs-7">
							<div className="tab-content" id="v-pills-tabContent">
								<div className="tab-pane fade show active" id="UA" role="tabpanel" aria-labelledby="unified">
									<h4>{textData.layer3.detail.matrix1.heading2} </h4>
									<ul>
										<li><span>{textData.layer3.detail.matrix1.points.point1.serial_no} </span>{textData.layer3.detail.matrix1.points.point1.text}</li>
										<li><span>{textData.layer3.detail.matrix1.points.point2.serial_no} </span>{textData.layer3.detail.matrix1.points.point2.text}</li>
										<li><span>{textData.layer3.detail.matrix1.points.point3.serial_no} </span>{textData.layer3.detail.matrix1.points.point3.text}</li>
									</ul>
								</div>

								<div className="tab-pane fade" id="SDI" role="tabpanel" aria-labelledby="streamlined">
									<h4>{textData.layer3.detail.matrix2.heading2} </h4>
									<ul>
										<li><span>{textData.layer3.detail.matrix2.points.point1.serial_no} </span>{textData.layer3.detail.matrix2.points.point1.text}</li>
										<li><span>{textData.layer3.detail.matrix2.points.point2.serial_no} </span>{textData.layer3.detail.matrix2.points.point2.text}</li>
										<li><span>{textData.layer3.detail.matrix2.points.point3.serial_no} </span>{textData.layer3.detail.matrix2.points.point3.text}</li>
									</ul>
								</div>

								<div className="tab-pane fade" id="SDW" role="tabpanel" aria-labelledby="scalabale">
									<h4>{textData.layer3.detail.matrix3.heading2} </h4>
									<ul>
										<li><span>{textData.layer3.detail.matrix3.points.point1.serial_no} </span>{textData.layer3.detail.matrix3.points.point1.text}</li>
										<li><span>{textData.layer3.detail.matrix3.points.point2.serial_no} </span>{textData.layer3.detail.matrix3.points.point2.text}</li>
									</ul>
								</div>

								<div className="tab-pane fade" id="DEV" role="tabpanel" aria-labelledby="visualization">
									<h4>{textData.layer3.detail.matrix4.heading2} </h4>
									<ul>
										<li><span>{textData.layer3.detail.matrix4.points.point1.serial_no} </span>{textData.layer3.detail.matrix4.points.point1.text}</li>
										<li><span>{textData.layer3.detail.matrix4.points.point2.serial_no} </span>{textData.layer3.detail.matrix4.points.point2.text}</li>
										<li><span>{textData.layer3.detail.matrix4.points.point3.serial_no} </span>{textData.layer3.detail.matrix4.points.point3.text}</li>
										<li><span>{textData.layer3.detail.matrix4.points.point3.serial_no} </span>{textData.layer3.detail.matrix4.points.point3.text}</li>
									</ul>
								</div>
								<div className="tab-pane fade" id="AAML" role="tabpanel" aria-labelledby="advanced">
									<h4>{textData.layer3.detail.matrix5.heading2} </h4>
									<ul>
										<li><span>{textData.layer3.detail.matrix5.points.point1.serial_no} </span>{textData.layer3.detail.matrix5.points.point1.text}</li>
										<li><span>{textData.layer3.detail.matrix5.points.point2.serial_no} </span>{textData.layer3.detail.matrix5.points.point2.text}</li>
										<li><span>{textData.layer3.detail.matrix5.points.point3.serial_no} </span>{textData.layer3.detail.matrix5.points.point3.text}</li>
									</ul>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>

			<div className="container-fluid">
				<div className="container">
					<div className="row">
						<div className="panel-group panel-2" id="accordion" role="tablist" aria-multiselectable="true">
							<h1>{textData.layer4.heading}</h1>
							<div className="row">
								<div className="col-lg-6 col-md-6 col-sm-6 col-xs-6">
								{
                                    textData.layer4.collapsibles_left.map((item, index) => {
                                        left_index = index
                                        return(
                                            <div className="panel panel-default">
                                                <div className="panel-heading" role="tab" id={ "heading" + index }>
                                                    <h4 className="panel-title"><a className={`collapsed ${activePanel === "collapse" + index ? 'active' : ''}`} role="button" onClick={() => togglePanel("collapse" + index)}
                                                        aria-expanded={activePanel === "collapse" + index ? 'true' : 'false'} aria-controls={ "collapse" + index } href="#/"> {item.title} </a></h4>
                                                </div>
                                                <div className={`collapse ${activePanel === "collapse" + index ? 'show' : ''}`} id={"collapse" + index} aria-labelledby={"heading" + index}
                                                    data-parent="#accordion"> <div id={"collapse" + index} className="panel-collapse collapse" role="tabpanel" aria-labelledby={"heading" + index}></div>
                                                    <div className="panel-body">
                                                        <p>{item.body}</p>
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                    })
                                }
								</div>
								<div className="col-lg-6 col-md-6 col-sm-6 col-xs-6">
								{
                                    textData.layer4.collapsibles_right.map((item, index) => {
                                        index = index + left_index + 1
                                        return(
                                            <div className="panel panel-default">
                                                <div className="panel-heading" role="tab" id={ "heading" + index }>
                                                    <h4 className="panel-title"><a className={`collapsed ${activePanel === "collapse" + index ? 'active' : ''}`} role="button" onClick={() => togglePanel("collapse" + index)}
                                                        aria-expanded={activePanel === "collapse" + index ? 'true' : 'false'} aria-controls={ "collapse" + index } href="#/"> {item.title} </a></h4>
                                                </div>
                                                <div className={`collapse ${activePanel === "collapse" + index ? 'show' : ''}`} id={"collapse" + index} aria-labelledby={"heading" + index}
                                                    data-parent="#accordion"> <div id={"collapse" + index} className="panel-collapse collapse" role="tabpanel" aria-labelledby={"heading" + index}></div>
                                                    <div className="panel-body">
                                                        <p>{item.body}</p>
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                    })
                                }
									{/* <div className="panel panel-default">
										<div className="panel-heading" role="tab" id="headingFive">
											<h4 className="panel-title"><a className={`collapsed ${activePanel === 'collapseFive' ? 'active' : ''}`} role="button" onClick={() => togglePanel('collapseFive')}
												aria-expanded={activePanel === 'collapseFive' ? 'true' : 'false'} aria-controls="collapseFive"> {textData.layer4.collapsibles.collapsible5.title}</a></h4>
										</div>
										<div className={`collapse ${activePanel === 'collapseFive' ? 'show' : ''}`} id="collapseFive" aria-labelledby="headingFive"
											data-parent="#accordion"> <div id="collapseFive" className="panel-collapse collapse" role="tabpanel" aria-labelledby="headingFive"></div>
											<div className="panel-body">
												<p>{textData.layer4.collapsibles.collapsible5.body}</p>
											</div>
										</div>
									</div>
									<div className="panel panel-default">
										<div className="panel-heading" role="tab" id="headingSix">
											<h4 className="panel-title"><a className={`collapsed ${activePanel === 'collapseSix' ? 'active' : ''}`} role="button" onClick={() => togglePanel('collapseSix')}
												aria-expanded={activePanel === 'collapseSix' ? 'true' : 'false'} aria-controls="collapseSix">{textData.layer4.collapsibles.collapsible6.title}</a></h4>
										</div>
										<div className={`collapse ${activePanel === 'collapseSix' ? 'show' : ''}`} id="collapseSix" aria-labelledby="headingSix"
											data-parent="#accordion"> <div id="collapseSix" className="panel-collapse collapse" role="tabpanel" aria-labelledby="headingSix"></div>
											<div className="panel-body">
												<p>{textData.layer4.collapsibles.collapsible6.body}</p>
											</div>
										</div>
									</div>
									<div className="panel panel-default">
										<div className="panel-heading" role="tab" id="headingSeven">
											<h4 className="panel-title"><a className={`collapsed ${activePanel === 'collapseSeven' ? 'active' : ''}`} role="button" onClick={() => togglePanel('collapseSeven')}
												aria-expanded={activePanel === 'collapseSeven' ? 'true' : 'false'} aria-controls="collapseSeven"> {textData.layer4.collapsibles.collapsible7.title}</a></h4>
										</div>
										<div className={`collapse ${activePanel === 'collapseSeven' ? 'show' : ''}`} id="collapseSeven" aria-labelledby="headingSeven"
											data-parent="#accordion"> <div id="collapseSeven" className="panel-collapse collapse" role="tabpanel" aria-labelledby="headingSeven"></div>
											<div className="panel-body">
												<p>{textData.layer4.collapsibles.collapsible7.body}</p>
											</div>
										</div>
									</div>
									<div className="panel panel-default">
										<div className="panel-heading" role="tab" id="headingEight">
											<h4 className="panel-title"><a className={`collapsed ${activePanel === 'collapseEight' ? 'active' : ''}`} role="button" onClick={() => togglePanel('collapseEight')}
												aria-expanded={activePanel === 'collapseEight' ? 'true' : 'false'} aria-controls="collapseEight"> {textData.layer4.collapsibles.collapsible8.title}</a></h4>
										</div>
										<div className={`collapse ${activePanel === 'collapseEight' ? 'show' : ''}`} id="collapseEight" aria-labelledby="headingEight"
											data-parent="#accordion"> <div id="collapseEight" className="panel-collapse collapse" role="tabpanel" aria-labelledby="headingEight"></div>
											<div className="panel-body">
												<p>{textData.layer4.collapsibles.collapsible8.body}</p>
											</div>
										</div>
									</div> */}
								</div>
							</div>
						</div>
					</div>
					<div className="row iso">
						<h1>{textData.layer5.heading}</h1>
						<div className="col-lg-5 col-md-5 col-sm-5 col-xs-5">
							<img src={textData.layer5.img} alt="Certificate" className="img-fluid" />
						</div>
						<div className="col-lg-7 col-md-7 col-sm-7 col-xs-7">
							<div id="carouselExample" className="carousel slide">
								<div className="carousel-inner">
								{
                                    textData.layer5.editable_details.map((item, index) => {
                                        if (index === 0 ) {
                                            return( 
                                                <div className={ "carousel-item carousel-item-".concat(String.toString(index + 1)).concat(" active") }>
                                                    <div className="row">
														<div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
															<div className="card-2">
																<h5>{item.heading}</h5>
																<img src={item.img} alt="Certificate" className="img-fluid" />
																<p>{item.body}</p>
															</div>
														</div>
													</div>
                                                </div>)
                                            } else {
                                                return( 
                                                <div className={ "carousel-item carousel-item-".concat(String.toString(index + 1)) }>
                                                    <div className="row">
														<div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
															<div className="card-2">
																<h5>{item.heading}</h5>
																<img src={item.img} alt="Certificate" className="img-fluid" />
																<p>{item.body}</p>
															</div>
														</div>
													</div>
                                                </div>
                                            )}
                                        })
                                        
                                }
								</div>
								<div className="control-btn-5">
									<button className="carousel-control-next" type="button" data-bs-target="#carouselExample" data-bs-slide="next">
										<i className="fa-solid fa-arrow-right"></i>
										<span className="carousel-control-next-icon visually-hidden" aria-hidden="true"></span>
										<span className="visually-hidden">{textData.layer5.button_next}</span>
									</button>
									<button className="carousel-control-prev" type="button" data-bs-target="#carouselExample" data-bs-slide="prev">
										<span className="carousel-control-prev-icon visually-hidden" aria-hidden="true"></span>
										<i className="fa-solid fa-arrow-left"></i>
										<span className="visually-hidden">{textData.layer5.button_previous}</span>
									</button>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>

			<div className="container-fluid services-home">
				<div className="container">
					<h1><span>{textData.layer6.heading}</span></h1>
					<p>{textData.layer6.body}</p>
					{/* <div className="more-1">
						<a href="/services">Explore <i className="fa-solid fa-arrow-right"></i></a>
					</div> */}
					<div className="row">
						<div className="col-lg-2 col-md-2 col-sm-12 col-xs-12"></div>
						<div className="col-lg-2 col-md-2 col-sm-12 col-xs-12">
							<div className="module-border-wrap">
								<div className="module">
									<a href={textData.layer6.details.module1.link} id="pdng">{textData.layer6.details.module1.label}</a>
								</div>
							</div>
						</div>
						<div className="col-lg-2 col-md-2 col-sm-12 col-xs-12">
							<div className="module-border-wrap">
								<div className="module">
									<a href={textData.layer6.details.module2.link} id="pdng">{textData.layer6.details.module2.label}</a>
								</div>
							</div>
						</div>
						<div className="col-lg-2 col-md-2 col-sm-12 col-xs-12">
							<div className="module-border-wrap">
								<div className="module">
									<a href={textData.layer6.details.module3.link} id="pdng">{textData.layer6.details.module3.label}</a>
								</div>
							</div>
						</div>
						<div className="col-lg-2 col-md-2 col-sm-12 col-xs-12">
							<div className="module-border-wrap">
								<div className="module">
									<a href={textData.layer6.details.module4.link} id="pdng">{textData.layer6.details.module4.label}</a>
								</div>
							</div>
						</div>
						<div className="col-lg-2 col-md-2 col-sm-12 col-xs-12"></div>
						<div className="col-lg-2 col-md-2 col-sm-12 col-xs-12"></div>
						<div className="col-lg-3 col-md-3 col-sm-12 col-xs-12">
							<div className="module-border-wrap">
								<div className="module">
									<a href={textData.layer6.details.module5.link} id="pdng">{textData.layer6.details.module5.label}</a>
								</div>
							</div>
						</div>
						<div className="col-lg-3 col-md-3 col-sm-12 col-xs-12">
							<div className="module-border-wrap">
								<div className="module">
									<a href={textData.layer6.details.module6.link} id="pdng">{textData.layer6.details.module6.label}</a>
								</div>
							</div>
						</div>
						<div className="col-lg-2 col-md-2 col-sm-12 col-xs-12">
							<div className="module-border-wrap">
								<div className="module">
									<a href={textData.layer6.details.module7.link} id="pdng">{textData.layer6.details.module7.label}</a>
								</div>
							</div>
						</div>
						<div className="col-lg-2 col-md-2 col-sm-12 col-xs-12"></div>
					</div>
				</div>
			</div>

			<div className="container-fluid trust">
				<div className="container trusted-img">
					<div className="row">
						<h1>{textData.layer7.heading}</h1>
						<div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
							<img src={textData.layer7.img} alt="logos" className="img-fluid" />

						</div>
					</div>
				</div>
			</div>

			<div className="container-fluid alliance">
				<div className="container">
					<div className="row">
						<div className="col-lg-7 col-md-6 col-sm-12 col-xs-12">
							<div className="heading">
								<h1><span>{textData.layer8.heading.text1}</span>{textData.layer8.heading.text2}<span>{textData.layer8.heading.text3}</span></h1>
							</div>
							<div className="more-2">
								<a href={textData.layer8.button.url}>{textData.layer8.button.text}<i className="fa-solid fa-arrow-right"></i></a>
							</div>
						</div>
						<div className="col-lg-5 col-md-6 col-sm-12 col-xs-12">
							<div className="gif">
								<img src={textData.layer8.button.img} alt="gif" className="img-fluid" />
							</div>
						</div>
					</div>
				</div>
			</div>
            <div className="container-fluid ContactForm">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                            <ContactForm />
                        </div>
                    </div>
                </div>
            </div>
            <div className="container-fluid tabg alliance">
				<div className="TA-bg">
					<div className="container">
						<div className="row">
							<h1>{textData.layer9.heading}</h1>
							<div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
								<img src={textData.layer9.img} alt="logos" className="img-fluid" />

							</div>
						</div>
					</div>
				</div>
			</div>

		</div>
	)
}

export default SecurityServices;