
import React, { useEffect } from 'react';
import AOS from 'aos';
import page_json from './config/config.json';
import ContactForm from './ContactForm';
import { Helmet } from 'react-helmet';

const textDataUrl = page_json.page_jsons.HealthcareCs1;
const textDataResponse = await fetch(textDataUrl);
const textData = await textDataResponse.json();

function HealthcareCs1() {
	useEffect(() => {
		AOS.init(); // Initialize AOS
	}, []);

	return (
		<div data-aos="fade-up">
			<Helmet>
                <meta name="description" content="Integrated Experiences for providers and patients" />
                <title>Integrated User Experiences | Healthcare | Engineersmind</title>
            </Helmet>
			<div className="container-fluid m-0 p-0 brd-crmb">
				<div className="container">
					<div className="row">
						<div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
							<nav aria-label="breadcrumb">
								<ol className="breadcrumb">
									<li className="breadcrumb-item"><a href="/">Home</a></li>
									<li className="breadcrumb-item"><a href="/Industries/Healthcare">Healthcare</a></li>
									<li className="breadcrumb-item active" aria-current="page">Integrated User Experiences</li>
								</ol>
							</nav>
						</div>
					</div>
				</div>
			</div>

			<div className="container-fluid casestudy">
				<div className="container">
					<h1><span>{textData.layer1.heading1.text1}</span>{textData.layer1.heading1.text2}</h1>
					<div className="row">
						<div className="col-lg-4 col-md-4 col-sm-12 col-xs-12">
							<div className="casestudy1">
								<img src={textData.layer1.details.data1.img} alt="gif" className="img-fluid" />
								<h4>{textData.layer1.details.data1.heading}</h4>
								<p>{textData.layer1.details.data1.body}</p>
							</div>
						</div>
						<div className="col-lg-4 col-md-4 col-sm-12 col-xs-12">
							<div className="casestudy1">
								<img src={textData.layer1.details.data2.img} alt="gif" className="img-fluid" />
								<h4>{textData.layer1.details.data2.heading}</h4>
								<p>{textData.layer1.details.data2.body}</p>
							</div>

						</div>
						<div className="col-lg-4 col-md-4 col-sm-12 col-xs-12">
							<div className="casestudy1">
								<img src={textData.layer1.details.data3.img} alt="gif" className="img-fluid" />
								<h4>{textData.layer1.details.data3.heading}</h4>
								<p>{textData.layer1.details.data3.body}</p>
							</div>
						</div>
						<h5><span>{textData.layer1.heading2}</span></h5>
						<div className="col-lg-5 col-md-5 col-sm-12 col-xs-12">
							<div className="casestudy1">
								<p>{textData.layer1.body}</p>
							</div>
						</div>
					</div>
				</div>
			</div>

			<div className="container-fluid framework">
				<div className="container">
					<h1>{textData.layer2.heading}</h1>
					<div className="row">
						<div className="col-lg-4 col-md-4 col-sm-12 col-xs-12">
							<div className="frame-img">
								<img src={textData.layer2.details.data1.img} alt="gif" className="img-fluid" />
								<p>{textData.layer2.details.data1.title}</p>
							</div>
							<p>{textData.layer2.details.data1.body}</p>
						</div>
						<div className="col-lg-8 col-md-8 col-sm-12 col-xs-12">
							<div className="frame-text">
								<p><span>{textData.layer2.details.data1.points.point1.serial_no}</span> {textData.layer2.details.data1.points.point1.body}</p>
								<p><span>{textData.layer2.details.data1.points.point2.serial_no}</span> {textData.layer2.details.data1.points.point2.body}</p>
								<p><span>{textData.layer2.details.data1.points.point3.serial_no}</span> {textData.layer2.details.data1.points.point3.body}</p>
							</div>
						</div>
						&nbsp;
						<hr />
						<div className="col-lg-4 col-md-4 col-sm-12 col-xs-12">
							<div className="frame-img">
								<img src={textData.layer2.details.data2.img} alt="gif" className="img-fluid" />
								<p>{textData.layer2.details.data2.title}</p>
							</div>
							<p>{textData.layer2.details.data2.body}</p>
						</div>
						<div className="col-lg-8 col-md-8 col-sm-12 col-xs-12">
							<div className="frame-text">
								<p><span>{textData.layer2.details.data2.points.point1.serial_no}</span> {textData.layer2.details.data2.points.point1.body}</p>
								<p><span>{textData.layer2.details.data2.points.point2.serial_no}</span> {textData.layer2.details.data2.points.point2.body}</p>
							</div>
						</div>
						&nbsp;
						<hr />
						<div className="col-lg-4 col-md-4 col-sm-12 col-xs-12">
							<div className="frame-img">
								<img src={textData.layer2.details.data3.img} alt="gif" className="img-fluid" />
								<p>{textData.layer2.details.data3.title}</p>
							</div>
							<p>{textData.layer2.details.data3.body}</p>
						</div>
						<div className="col-lg-8 col-md-8 col-sm-12 col-xs-12">
							<div className="frame-text">
								<p><span>{textData.layer2.details.data3.points.point1.serial_no}</span> {textData.layer2.details.data3.points.point1.body}</p>
								<p><span>{textData.layer2.details.data3.points.point2.serial_no}</span> {textData.layer2.details.data3.points.point2.body}</p>
								<p><span>{textData.layer2.details.data3.points.point3.serial_no}</span> {textData.layer2.details.data3.points.point3.body}</p>
								<p><span>{textData.layer2.details.data3.points.point4.serial_no}</span> {textData.layer2.details.data3.points.point4.body}</p>
							</div>
						</div>
						&nbsp;
						<h1>{textData.layer3.heading}</h1>
						<p>{textData.layer3.body.text1}</p>&nbsp;
						<p>{textData.layer3.body.text2}</p>
						<div className="col-lg-2 col-md-2 col-sm-12 col-xs-12">
							<div className="module-border-wrap">
								<div className="module">
									<p id="pdng">{textData.layer3.modules.module1}</p>
								</div>
							</div>
						</div>
						<div className="col-lg-2 col-md-2 col-sm-12 col-xs-12">
							<div className="module-border-wrap">
								<div className="module">
									<p id="pdng">{textData.layer3.modules.module2}</p>
								</div>
							</div>
						</div>
						<div className="col-lg-2 col-md-2 col-sm-12 col-xs-12">
							<div className="module-border-wrap">
								<div className="module">
									<p id="pdng">{textData.layer3.modules.module3}</p>
								</div>
							</div>
						</div>
						<div className="col-lg-2 col-md-2 col-sm-12 col-xs-12">
							<div className="module-border-wrap">
								<div className="module">
									<p id="pdng">{textData.layer3.modules.module4}</p>
								</div>
							</div>
						</div>
						<div className="col-lg-3 col-md-3 col-sm-12 col-xs-12">
							<div className="module-border-wrap">
								<div className="module">
									<p id="pdng">{textData.layer3.modules.module5}</p>
								</div>
							</div>
						</div>
						<img src={textData.layer3.img} className="img-fluid" alt="aws" />
					</div>
				</div>
			</div>

			<div className="container-fluid status">
				<div className="container">
					<h1><span>{textData.layer4.heading1.text1}</span>{textData.layer4.heading1.text2}<br />{textData.layer4.heading1.text3}</h1>
					<div className="row">
						<div className="col-lg-4 col-md-4 col-sm-12 col-xs-12">
							<div className="frame-img-1">
								<img src={textData.layer4.details.data1.img} alt="gif" className="img-fluid" />
								<p>{textData.layer4.details.data1.body}</p>
							</div>
							<div className="frame-img-1">
								<img src={textData.layer4.details.data2.img} alt="gif" className="img-fluid" />
								<p>{textData.layer4.details.data2.body}</p>
							</div>
							<div className="frame-img-1">
								<img src={textData.layer4.details.data3.img} alt="gif" className="img-fluid" />
								<p>{textData.layer4.details.data3.body}</p>
							</div>
						</div>
						<div className="col-lg-8 col-md-8 col-sm-12 col-xs-12">
							<ul className="numbers">
								<li><span>{textData.layer4.info.info1.serial_no}</span>{textData.layer4.info.info1.body}</li>
								<li><span>{textData.layer4.info.info2.serial_no}</span>{textData.layer4.info.info2.body}</li>
								<li><span>{textData.layer4.info.info3.serial_no}</span>{textData.layer4.info.info3.body}</li>
								<li><span>{textData.layer4.info.info4.serial_no}</span>{textData.layer4.info.info4.body}</li>
								<li><span>{textData.layer4.info.info5.serial_no}</span>{textData.layer4.info.info5.body}</li>
							</ul>
						</div>
					</div>&nbsp;
					<div className="status2">
						<h1><span>{textData.layer4.heading2}</span></h1>
						<p>{textData.layer4.body}</p>
					</div>

				</div>
			</div>
			<div className="container-fluid ContactForm">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                            <ContactForm />
                        </div>
                    </div>
                </div>
            </div>
			<div className="TA-bg">
				<div className="container">
					<div className="row">
						<h1>{textData.layer5.heading}</h1>
						<div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
							<img src={textData.layer5.img} alt="logos" className="img-fluid" />

						</div>
					</div>
				</div>
			</div>


		</div>
	)
}

export default HealthcareCs1;