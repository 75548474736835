
import React, { useEffect } from 'react';
import AOS from 'aos';
import page_json from './config/config.json'
import { Helmet } from 'react-helmet';

const textDataUrl = page_json.page_jsons.Body;
const textDataResponse = await fetch(textDataUrl);
const textData = await textDataResponse.json();

function Body() {
    useEffect(() => {
        AOS.init();
    }, []);
    

    return (
        <div data-aos="fade-up">
            <Helmet>
                <meta name="description" content="Reimagine and Transform your Enterprise, Empower your organization with Data and AI, EM Tech Talent Solutions Your gateway to innovation, excellence & growth" />
                <title>About Us Page</title>
            </Helmet>
            <div className="container-fluid p-0">
                <div id="carouselExampleAutoplaying" className="carousel slide">
                    <div className="carousel-inner">
                        {
                            textData.editable_carousel_banner.map((item, index) => {
                                if (index === 0 ) {
                                    return( 
                                        <div className={ "carousel-item carousel-item-".concat(String.toString(index + 1)).concat(" active") }>
                                            <div className="row">
                                                <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                                    <img src={item.img} className="d-block w-100" alt="banner-img" />
                                                    <div className="banner-text">
                                                        <div className="container">
                                                            <div className="row">
                                                                <div className="col-lg-8 col-md-8 col-sm-8 col-xs-8">
                                                                    <h1 className="animate__animated animate__fadeInLeft"><span>{item.heading.text1}</span>{item.heading.text2}<br /> <span>{item.heading.text3}</span> {item.heading.text4}<br />{item.heading.text5}</h1>
                                                                    <a href={item.button.url} className="animate__animated animate__fadeInLeft">{item.button.text} <i className="fa-solid fa-arrow-right"></i></a>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="banner-logos">
                                                        <div className="container">
                                                            <div className="row">
                                                                <div className="col-lg-8 col-md-8 col-sm-8 col-xs-8">
                                                                    <img src={item.banner.img} className="img-dluid" alt="banner-logo" />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>)
                                    } else {
                                        return( 
                                        <div className={ "carousel-item carousel-item-".concat(String.toString(index + 1)) }>
                                            <div className="row">
                                                <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                                    <img src={item.img} className="d-block w-100" alt="banner-img" />
                                                    <div className="banner-text">
                                                        <div className="container">
                                                            <div className="row">
                                                                <div className="col-lg-8 col-md-8 col-sm-8 col-xs-8">
                                                                    <h1 className="animate__animated animate__fadeInLeft"><span>{item.heading.text1}</span>{item.heading.text2}<br /> <span>{item.heading.text3}</span> {item.heading.text4}<br />{item.heading.text5}</h1>
                                                                    <a href={item.button.url} className="animate__animated animate__fadeInLeft">{item.button.text} <i className="fa-solid fa-arrow-right"></i></a>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="banner-logos">
                                                        <div className="container">
                                                            <div className="row">
                                                                <div className="col-lg-8 col-md-8 col-sm-8 col-xs-8">
                                                                    <img src={item.banner.img} className="img-dluid" alt="banner-logo" />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    )}
                            })
                                
                        }
                    </div>
                    <div className="control-btn-2">
                        <button className="carousel-control-next" type="button" data-bs-target="#carouselExampleAutoplaying" data-bs-slide="next">
                            <i className="fa-solid fa-arrow-right"></i>
                            <span className="carousel-control-next-icon visually-hidden" aria-hidden="true"></span>
                            <span className="visually-hidden">Next</span>
                        </button>
                        <button className="carousel-control-prev" type="button" data-bs-target="#carouselExampleAutoplaying" data-bs-slide="prev">
                            <span className="carousel-control-prev-icon visually-hidden" aria-hidden="true"></span>
                            <i className="fa-solid fa-arrow-left"></i>
                            <span className="visually-hidden">Previous</span>
                        </button>
                    </div>
                </div>
            </div>

            <div className="container-fluid layer-2">
                <div className="container">
                    <h1>{textData.layer2.heading}</h1>
                    <div className="card-header">
                        <ul className="nav nav-tabs justify-content-center" role="tablist">
                            <li className="nav-item">
                                <a className="nav-link active" data-bs-toggle="tab" href="#financial" role="tab">{textData.layer2.subheadings.subheading1}</a>
                            </li>
                            <li className="nav-item">
                                <a className="nav-link" data-bs-toggle="tab" href="#healthcare" role="tab">{textData.layer2.subheadings.subheading2}</a>
                            </li>
                            <li className="nav-item">
                                <a className="nav-link" data-bs-toggle="tab" href="#manufacturing" role="tab">{textData.layer2.subheadings.subheading3}</a>
                            </li>
                        </ul>
                    </div>&nbsp;
                    <div className="card-body">
                        <div className="tab-content text-center">
                            <div className="tab-pane active" id="financial" role="tabpanel">
                                <div className="indus-txt">
                                    <p>{textData.layer2.details.detail1.body.text1}</p>
                                    <p>{textData.layer2.details.detail1.body.text2}</p>
                                    <p>{textData.layer2.details.detail1.body.text3}</p>
                                    <a href={textData.layer2.details.detail1.button.link}>{textData.layer2.details.detail1.button.text} <i className="fa-solid fa-arrow-right"></i></a>
                                </div>
                            </div>
                            <div className="tab-pane" id="healthcare" role="tabpanel">
                                <div className="indus-txt">
                                    <p>{textData.layer2.details.detail2.body.text1}</p>
                                    <p>{textData.layer2.details.detail2.body.text2}</p>
                                    <a href={textData.layer2.details.detail2.button.link}>{textData.layer2.details.detail2.button.text} <i className="fa-solid fa-arrow-right"></i></a>
                                </div>
                            </div>
                            <div className="tab-pane" id="manufacturing" role="tabpanel">
                                <div className="indus-txt">
                                    <p>{textData.layer2.details.detail3.body.text1}</p>
                                    <p>{textData.layer2.details.detail3.body.text2}</p>
                                    <p>{textData.layer2.details.detail3.body.text3}</p>
                                    <a href={textData.layer2.details.detail3.button.link}>{textData.layer2.details.detail3.button.text}<i className="fa-solid fa-arrow-right"></i></a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="container-fliud testimonial">
                <h1>{textData.layer3.heading.text1}<br />{textData.layer3.heading.text2}</h1>
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                            <img src={textData.layer3.img} alt="logos" className="img-fluid" />

                        </div>
                    </div>
                </div>
            </div>

            <div className="container-fluid testi-home">
                <div className="container">
                    <h1>{textData.layer4.heading}</h1>
                    <div className="row">
                        <div className="col-lg-12 col-md 12 col-sm-12 col-xs-">
                            <div id="carouselExampleCaptions" className="carousel slide">
                                <div className="carousel-inner">
                                    {
                                        textData.layer4.editable_carousel.map((item, index) => {
                                            if (index === 0 ) {
                                                return( 
                                                    <div className={ "carousel-item carousel-item-".concat(String.toString(index + 1)).concat(" active") }>
                                                        <div className="row">
                                                            <div className="col-lg-2 col-md-2 col-sm-12 col-xs-12"></div>
                                                            <div className="col-lg-10 col-md-10 col-sm-12 col-xs-12">
                                                                <p><i className="fa-solid fa-quote-left"></i>{item.testimony}<i className="fa-solid fa-quote-right"></i></p>&nbsp;
                                                                <p><b>{item.name}</b><br /><i>{item.post}</i></p>
                                                                <div className="buttoncss">
                                                                    <div className="control-btn-3">
                                                                        <button className="carousel-control-next" type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide="next">
                                                                            <i className="fa-solid fa-arrow-right"></i>
                                                                            <span className="carousel-control-next-icon visually-hidden" aria-hidden="true"></span>
                                                                            <span className="visually-hidden">Next</span>
                                                                        </button>
                                                                        <button className="carousel-control-prev" type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide="prev">
                                                                            <span className="carousel-control-prev-icon visually-hidden" aria-hidden="true"></span>
                                                                            <i className="fa-solid fa-arrow-left"></i>
                                                                            <span className="visually-hidden">Previous</span>
                                                                        </button>
                                                                    </div>
                                                                    <p className="morecss">
                                                                        <a href={item.button.link}>{item.button.text} <i className="fa-solid fa-arrow-right"></i></a>
                                                                    </p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>)
                                                } else {
                                                    return( 
                                                    <div className={ "carousel-item carousel-item-".concat(String.toString(index + 1)) }>
                                                        <div className="row">
                                                            <div className="col-lg-2 col-md-2 col-sm-12 col-xs-12"></div>
                                                            <div className="col-lg-10 col-md-10 col-sm-12 col-xs-12">
                                                                <p><i className="fa-solid fa-quote-left"></i>{item.testimony}<i className="fa-solid fa-quote-right"></i></p>&nbsp;
                                                                <p><b>{item.name}</b><br /><i>{item.post}</i></p>
                                                                <div className="buttoncss">
                                                                    <div className="control-btn-3">
                                                                        <button className="carousel-control-next" type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide="next">
                                                                            <i className="fa-solid fa-arrow-right"></i>
                                                                            <span className="carousel-control-next-icon visually-hidden" aria-hidden="true"></span>
                                                                            <span className="visually-hidden">Next</span>
                                                                        </button>
                                                                        <button className="carousel-control-prev" type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide="prev">
                                                                            <span className="carousel-control-prev-icon visually-hidden" aria-hidden="true"></span>
                                                                            <i className="fa-solid fa-arrow-left"></i>
                                                                            <span className="visually-hidden">Previous</span>
                                                                        </button>
                                                                    </div>
                                                                    <p className="morecss">
                                                                        <a href={item.button.link}>{item.button.text} <i className="fa-solid fa-arrow-right"></i></a>
                                                                    </p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                )}
                                        })
                    
                                    }
                                </div><br />
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="container-fluid success extra-space">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                            <h1><span>{textData.layer5.heading.text1}</span>{textData.layer5.heading.text2}</h1>
                            <div id="carouselExampleInterval" className="carousel slide">
                                <div className="carousel-inner">
                                {
                                    textData.layer5.editable_carousel.map((item, index) => {
                                        if (index === 0 ) {
                                            return( 
                                                <div className={ "carousel-item carousel-item-".concat(String.toString(index + 1)).concat(" active") }>
                                                    <div className="row">
                                                        <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                                            <div className="success-text-1">
                                                                <p>{item.text.text1}<br />{item.text.text2}<br />{item.text.text3}</p><br />
                                                                <a href={item.button.link}>{item.button.text}<i className="fa-solid fa-arrow-right"></i></a>
                                                            </div>
                                                            <img src={item.img} className="d-block w-100" alt="banner-img" />
                                                        </div>
                                                    </div>
                                                </div>)
                                            } else {
                                                return( 
                                                <div className={ "carousel-item carousel-item-".concat(String.toString(index + 1)) }>
                                                   <div className="row">
                                                        <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                                            <div className="success-text-1">
                                                                <p>{item.text.text1}<br />{item.text.text2}<br />{item.text.text3}</p><br />
                                                                <a href={item.button.link}>{item.button.text}<i className="fa-solid fa-arrow-right"></i></a>
                                                            </div>
                                                            <img src={item.img} className="d-block w-100" alt="banner-img" />
                                                        </div>
                                                    </div>
                                                </div>
                                        )}
                                    })
                                        
                                }
                                </div>
                                <div className="control-btn-4">
                                    <button className="carousel-control-next" type="button" data-bs-target="#carouselExampleInterval" data-bs-slide="next">
                                        <i className="fa-solid fa-arrow-right"></i>
                                        <span className="carousel-control-next-icon visually-hidden" aria-hidden="true"></span>
                                        <span className="visually-hidden">Next</span>
                                    </button>
                                    <button className="carousel-control-prev" type="button" data-bs-target="#carouselExampleInterval" data-bs-slide="prev">
                                        <span className="carousel-control-prev-icon visually-hidden" aria-hidden="true"></span>
                                        <i className="fa-solid fa-arrow-left"></i>
                                        <span className="visually-hidden">Previous</span>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="container-fluid services-home">
                <div className="container">
                    <h1><span>{textData.layer6.heading}</span></h1>
                    <p>{textData.layer6.body}</p>
                    {/* <div className="more-1">
                        <a href="/Services/Managed-IT-Services">Explore <i className="fa-solid fa-arrow-right"></i></a>
                    </div> */}
                    <div className="row">
                        <div className="col-lg-2 col-md-2 col-sm-12 col-xs-12"></div>
                        <div className="col-lg-2 col-md-2 col-sm-12 col-xs-12">
                            <div className="module-border-wrap">
                                <div className="module">
                                    <a href={textData.layer6.details.detail1.link} id="pdng">{textData.layer6.details.detail1.text}</a>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-2 col-md-2 col-sm-12 col-xs-12">
                            <div className="module-border-wrap">
                                <div className="module">
                                    <a href={textData.layer6.details.detail2.link} id="pdng">{textData.layer6.details.detail2.text}</a>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-2 col-md-2 col-sm-12 col-xs-12">
                            <div className="module-border-wrap">
                                <div className="module">
                                    <a href={textData.layer6.details.detail3.link} id="pdng">{textData.layer6.details.detail3.text}</a>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-2 col-md-2 col-sm-12 col-xs-12">
                            <div className="module-border-wrap">
                                <div className="module">
                                    <a href={textData.layer6.details.detail4.link} id="pdng">{textData.layer6.details.detail4.text}</a>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-2 col-md-2 col-sm-12 col-xs-12"></div>
                        <div className="col-lg-2 col-md-2 col-sm-12 col-xs-12"></div>
                        <div className="col-lg-3 col-md-3 col-sm-12 col-xs-12">
                            <div className="module-border-wrap">
                                <div className="module">
                                    <a href={textData.layer6.details.detail5.link} id="pdng">{textData.layer6.details.detail5.text}</a>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-3 col-sm-12 col-xs-12">
                            <div className="module-border-wrap">
                                <div className="module">
                                    <a href={textData.layer6.details.detail6.link} id="pdng">{textData.layer6.details.detail6.text}</a>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-2 col-md-2 col-sm-12 col-xs-12">
                            <div className="module-border-wrap">
                                <div className="module">
                                    <a href={textData.layer6.details.detail7.link} id="pdng">{textData.layer6.details.detail7.text}</a>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-2 col-md-2 col-sm-12 col-xs-12"></div>
                    </div>
                </div>
            </div>

            <div className="container-fluid">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-7 col-md-7 col-sm-12 col-xs-12">
                            <div className="value-text">
                                <h1><span>{textData.layer7.heading}</span></h1>
                                <h3>{textData.layer7.heading2.text1}<br />{textData.layer7.heading2.text2}</h3>&nbsp;
                                <p>{textData.layer7.heading3.text1}<br />{textData.layer7.heading3.text2}</p><br />
                                <a href={textData.layer7.button.link}>{textData.layer7.button.text} <i className="fa-solid fa-arrow-right"></i></a>
                            </div>
                        </div>
                        <div className="col-lg-5 col-md-5 col-sm-12 col-xs-12">
                            <div className="value-img">
                                <img src={textData.layer7.img} className="img-fluid" alt="intro-img" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="container-fluid alliance">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-7 col-md-6 col-sm-12 col-xs-12">
                            <div className="heading">
                            <h1><span>{textData.layer8.heading.text1}</span>{textData.layer8.heading.text2}<span>{textData.layer8.heading.text3}</span></h1>
                            </div>
                            <div className="more-2">
                            <a href={textData.layer8.button.url}>{textData.layer8.button.text}<i className="fa-solid fa-arrow-right"></i></a>
                            </div>
                        </div>
                        <div className="col-lg-5 col-md-6 col-sm-12 col-xs-12">
                            <div className="gif">
                            <img src={textData.layer8.button.img} alt="gif" className="img-fluid" />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="TA-bg">
                    <div className="container">
                        <div className="row">
                            <h1>{textData.layer9.heading}</h1>
                            <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                            <img src={textData.layer9.img} alt="logos" className="img-fluid" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>

    )
}

export default Body;