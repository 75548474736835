import React, { useEffect } from 'react';
import AOS from 'aos';
// import textData from '../Jsons/ContentManagementSystem.json'
import page_json from './config/config.json';
import ContactForm from './ContactForm';
import { Helmet } from 'react-helmet';

const textDataUrl = page_json.page_jsons.ContentManagementSystem;
const textDataResponse = await fetch(textDataUrl);
const textData = await textDataResponse.json();


function ContentManagementSystem() {
	useEffect(() => {
		AOS.init();
	}, []);

	return (
		<div data-aos="fade-up" >
			<Helmet>
                <meta name="description" content="Amplify. Unlock. Empower. The entire spectrum of Financial Advisors with our integrated and comprehensive Content Management platform" />
                <title>Content Management System | Financial Services | Engineersmind</title>
            </Helmet>
			<div className="container-fluid m-0 p-0 brd-crmb">
				<div className="container">
					<div className="row">
						<div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
							<nav aria-label="breadcrumb">
								<ol className="breadcrumb">
									<li className="breadcrumb-item"><a href="/">Home</a></li>
									<li className="breadcrumb-item"><a href="/Industries/Financial-Services">Financial Services</a></li>
									<li className="breadcrumb-item active" aria-current="page">Content Management System</li>
								</ol>
							</nav>
						</div>
					</div>
				</div>
			</div>

			<div className="container-fluid casestudy">
				<div className="container">
					<h1><span>{textData.layer1.title}</span></h1>
					<p>{textData.layer1.subtitle}</p>&nbsp;
					<div className="row">
						<div className="col-lg-4 col-md-4 col-sm-12 col-xs-12">
							<div className="casestudy1">
								<img src={textData.layer1.sections.section1.image} alt="gif" className="img-fluid" />
								<h4>{textData.layer1.sections.section1.title}</h4>
								<p>{textData.layer1.sections.section1.description}</p>
							</div>
						</div>
						<div className="col-lg-4 col-md-4 col-sm-12 col-xs-12">
							<div className="casestudy1">
								<img src={textData.layer1.sections.section2.image} alt="gif" className="img-fluid" />
								<h4>{textData.layer1.sections.section2.title}</h4>
								<p>{textData.layer1.sections.section2.description}</p>
							</div>
						</div>
						<div className="col-lg-4 col-md-4 col-sm-12 col-xs-12">
							<div className="casestudy1">
								<img src={textData.layer1.sections.section3.image} alt="gif" className="img-fluid" />
								<h4>{textData.layer1.sections.section3.title}</h4>
								<p>{textData.layer1.sections.section3.description}</p>
							</div>
						</div>
					</div>
				</div>
			</div>

			<div className="container-fluid cs-solution-2">
				<div className="container">
					<h1><span>{textData.layer2.title.text1}</span>{textData.layer2.title.text2}</h1>
					<p><i>{textData.layer2.subtitle}</i></p><br /><br />
					<h5><span>{textData.layer3.title}</span></h5>
					<p>{textData.layer3.subtitle}</p>&nbsp;
					<h5><span>{textData.layer4.title}</span></h5>
					<div className="ul-list-1">
						<div className="row">
							<div className="col-lg-4 col-md-4 col-sm-12 col-xs-12">
								<p>{textData.layer4.solutions.solution1.serial_no}</p>
								<ul>
									<li> {textData.layer4.solutions.solution1.points.point1}</li>
									<li> {textData.layer4.solutions.solution1.points.point2}</li>
									<li> {textData.layer4.solutions.solution1.points.point3}</li>
									<li> {textData.layer4.solutions.solution1.points.point4}</li>
									<li> {textData.layer4.solutions.solution1.points.point5}</li>
								</ul>
							</div>
							<div className="col-lg-4 col-md-4 col-sm-12 col-xs-12">
								<p>{textData.layer4.solutions.solution2.serial_no}</p>
								<ul>
									<li> {textData.layer4.solutions.solution2.points.point1}</li>
									<li> {textData.layer4.solutions.solution2.points.point2}</li>
									<li> {textData.layer4.solutions.solution2.points.point3}</li>
									<li> {textData.layer4.solutions.solution2.points.point4}</li>
								</ul>
							</div>
							<div className="col-lg-4 col-md-4 col-sm-12 col-xs-12">
								<p>{textData.layer4.solutions.solution3.serial_no}</p>
								<ul>
									<li> {textData.layer4.solutions.solution3.points.point1}</li>
									<li> {textData.layer4.solutions.solution3.points.point2}</li>
									<li> {textData.layer4.solutions.solution3.points.point3}</li>
								</ul>
							</div>
						</div>
					</div>
				</div>
			</div>

			<div className="container-fluid Features">
				<div className="container">
					<h1>{textData.layer5.title1}</h1>
					<div className="row">
						<div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
							<img src={textData.layer5.img1} alt="gif" className="img-fluid" />
							<img src={textData.layer5.img2} alt="gif" className="img-fluid" />
						</div>
					</div><br /><br />
					<h1>{textData.layer5.title2}</h1>
					<p><i>{textData.layer5.subtitle2.text1}<b>{textData.layer5.subtitle2.text2}</b>{textData.layer5.subtitle2.text3}<b>{textData.layer5.subtitle2.text4}</b>{textData.layer5.subtitle2.text5}</i></p><br />
				</div>
			</div>

			<div className="container-fluid status">
				<div className="container">
					<h1><span>{textData.layer6.title.text1}</span>{textData.layer6.title.text2}</h1>
					<div className="row">
						<div className="col-lg-6 col-md-6 col-sm-12 col-xs-12">
							<h5><span>{textData.layer6.module.module1.title}</span></h5><br />
							<img src={textData.layer6.module.module1.img} alt="gif" className="img-fluid" />
							<ul className="disc">
								<li>{textData.layer6.module.module1.points.point1}</li>
								<li>{textData.layer6.module.module1.points.point2}</li>
							</ul>
						</div>
						<div className="col-lg-6 col-md-6 col-sm-12 col-xs-12">
							<h5><span>{textData.layer6.module.module2.title}</span></h5>&nbsp;
							<ul className="disc">
								<li>{textData.layer6.module.module2.points.point1}</li>
								<li>{textData.layer6.module.module2.points.point2}</li>
								<li>{textData.layer6.module.module2.points.point3}</li>
								<li>{textData.layer6.module.module2.points.point4}</li>
								<li>{textData.layer6.module.module2.points.point5}</li>
								<li>{textData.layer6.module.module2.points.point6}</li>
							</ul>
						</div>
					</div>
				</div>
			</div>
			<div className="container-fluid ContactForm">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                            <ContactForm />
                        </div>
                    </div>
                </div>
            </div>
			<div className="TA-bg">
				<div className="container">
					<div className="row">
						<h1>{textData.layer7.heading}</h1>
						<div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
							<img src={textData.layer7.img} alt="logos" className="img-fluid" />

						</div>
					</div>
				</div>
			</div>
		</div>

	)
}

export default ContentManagementSystem;