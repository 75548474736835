import React from "react";
import "react-dropzone-uploader/dist/styles.css";
import Dropzone from "react-dropzone-uploader";
import { Hourglass } from 'react-loader-spinner';
import axios from 'axios';
import page_json from './config/config.json'



const Uploader = ({ onUploadComplete }) => {
  var imgNames = [];
  const API_ENDPOINT = page_json.backend_apis.get_api;
  const handleChangeStatus = ({ meta, remove }, status) => {
    if (status === "done") {
      imgNames.push(meta.name);
    }
  };

  var requestList = [];
  const handleSubmit = async (files, allFiles) => {
    document.getElementsByClassName("dzu-dropzone")[0].style.display = "none";
    document.getElementsByClassName("loader")[0].style.display = "flex";
    let key = sessionStorage.getItem("key");
    imgNames.forEach((imgName) => {
      requestList.push(axios({
        method: "POST",
        url: API_ENDPOINT,
        headers: { 'Content-Type': 'application/json' },
        data: {
          name: imgName,
          key: key
        }
      }))
    });
    const responses = await Promise.all(requestList);
    var results = [];
    responses.forEach((response) => {
      files.forEach((file) => {
        if (file.meta.name === response.data.key) {
          results.push(fetch(response.data.url, {
            method: "PUT",
            body: file.file,
          }));
        }
      })
    });
    await Promise.all(results);
    allFiles.forEach(f => f.remove());
    document.getElementsByClassName("dzu-dropzone")[0].style.display = "flex";
    document.getElementsByClassName("loader")[0].style.display = "none";
    alert("UPLOAD SUCCESSFUL!");
    if (onUploadComplete) {
      onUploadComplete();
    }
    imgNames = [];
  };


  return (
    <div>
      <Dropzone
        onChangeStatus={handleChangeStatus}
        onSubmit={handleSubmit}
        accept="image/*,application/json"
        hjello
        multiple={true}
        canCancel={true}
        inputContent="Uploader: Drop A File"
        styles={{
          dropzone: { width: 400, maxHeight: 500 },
          dropzoneActive: { borderColor: "green", backgroundColor: '#50C878' },
          dropzoneReject: { borderColor: 'red', backgroundColor: '#DAA' },
          inputLabel: (files, extra) => (extra.reject ? { color: 'red' } : {}),
        }}
      />
      <div className="loader">
        <Hourglass
          height="80"
          width="80"
          ariaLabel="hourglass-loading"
          wrapperStyle={{}}
          wrapperClass=""
          colors={['#306cce', '#72a1ed']}
        />
      </div>

    </div>
  );
};

<Uploader />;

export default Uploader;