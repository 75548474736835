
import React, { useEffect } from 'react';
import AOS from 'aos';
import page_json from './config/config.json';
import ContactForm from './ContactForm';
import { Helmet } from 'react-helmet';

const textDataUrl = page_json.page_jsons.Testimonials;
const textDataResponse = await fetch(textDataUrl);
const textData = await textDataResponse.json();

function Testimonials() {
	useEffect(() => {
		AOS.init(); // Initialize AOS
	}, []);

	return (
		<div data-aos="fade-up">
			<Helmet>
                <meta name="description" content="Testimonials" />
                <title>Testimonials | Resources | Engineersmind</title>
            </Helmet>
			<div className="container-fluid m-0 p-0 brd-crmb">
				<div className="container">
					<div className="row">
						<div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
							<nav aria-label="breadcrumb">
								<ol className="breadcrumb">
									<li className="breadcrumb-item"><a href="/">Home </a></li>
									<li className="breadcrumb-item"><a href="/Resources">Resources </a></li>
									<li className="breadcrumb-item active" aria-current="page">Testimonials</li>
								</ol>
							</nav>
						</div>
					</div>
				</div>
			</div>

			<div className="container-fluid testi-heading">
				<div className="container">
					<h1>{textData.layer1.heading}</h1>
				</div>
			</div>

			<div className="container-fluid testi-inner">
				<div className="container">
					<div className="row">
						<div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
							{
								textData.layer2.editable_details.map((item, index) => {
									return (
										<div id="testi1" name="testi1">
											<h6>{item.client_name}</h6>
											<p><i>{item.post}</i></p>&nbsp;
											{
												item.body.map((sub_item, sub_index) => {
													return (
														<p>{sub_item}&nbsp;</p>
													)
												})
											}
											<hr />
										</div>
									)
								})
							}
						</div>
					</div>
				</div>
			</div>

			<div className="container-fluid alliance">
				<div className="container">
					<div className="row">
						<div className="col-lg-7 col-md-6 col-sm-12 col-xs-12">
							<div className="heading">
								<h1><span>{textData.layer4.heading.text1}</span>{textData.layer4.heading.text2}<span>{textData.layer4.heading.text3}</span></h1>
							</div>
							<div className="more-2">
								<a href={textData.layer4.button.url}>{textData.layer4.button.text}<i className="fa-solid fa-arrow-right"></i></a>
							</div>
						</div>
						<div className="col-lg-5 col-md-6 col-sm-12 col-xs-12">
							<div className="gif">
								<img src={textData.layer4.button.img} alt="gif" className="img-fluid" />
							</div>
						</div>
					</div>
				</div>
			</div>
            <div className="container-fluid ContactForm">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                            <ContactForm />
                        </div>
                    </div>
                </div>
            </div>
            <div className="container-fluid tabg alliance">
				<div className="TA-bg">
					<div className="container">
						<div className="row">
							<h1>{textData.layer5.heading}</h1>
							<div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
								<img src={textData.layer5.img} alt="logos" className="img-fluid" />

							</div>
						</div>
					</div>
				</div>
			</div>

		</div>
	)
}

export default Testimonials;