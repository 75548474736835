import React, { useEffect, useState } from 'react';
import AOS from 'aos';
import page_json from './config/config.json';
import Recaptcha from './ReCaptcha';

async function fetchData(url) {
    const response = await fetch(url);
    return response.json();
}

function ContactForm() {

    const [formData, setFormData] = useState({
        firstname: '',
        lastname: '',
        email: '',
        phone: '',
        organisation: '',
        country: '',
        comment: ''
    });
    const [message, setMessage] = useState('');
    const [isCaptchaValid, setIsCaptchaValid] = useState(false);
    const [isButtonDisabled, setIsButtonDisabled] = useState(true);
    const [countryList, setCountryList] = useState([]);
    const [textData, setTextData] = useState({});

    useEffect(() => {
        AOS.init(); // Initialize AOS
        
        // Load country list and text data
        const loadData = async () => {
            const countries = await fetchData(page_json.backend_apis.countries);
            setCountryList(countries.data || []);

            const textDataResponse = await fetchData(page_json.page_jsons.Contact);
            setTextData(textDataResponse);
        };

        loadData();
        
    }, [isCaptchaValid]);


    useEffect(() => {
		// Enable/disable the button based on form validation
		//alert("firstname-"+formData.firstname+"---lastname - "+formData.lastname+"---email - "+formData.email+"---phone - "+formData.phone+"---city - "+formData.city+"---country - "+formData.country+"---selectedOption - "+selectedOption+"---check1 - "+formData.check1+"---isCaptchaValid - "+isCaptchaValid);
		const isValid = 
		  formData.firstname && formData.lastname && formData.email && formData.phone &&
		  formData.organisation && formData.country && formData.comment && isCaptchaValid;
		
		setIsButtonDisabled(!isValid);
	  }, [formData, isCaptchaValid]);

    const handleInputChange = (event) => {
        const { name, value } = event.target;
        //var valueFrm = formData;
        if (name === 'phone') {
            const regex = /^[0-9()+-,]*$/;
            if (value.length === 0 || regex.test(value)) {
                setMessage('');
            } else {
                setMessage('Please enter valid phone number.');
            }
        }
        /*if (event?.target?.name === "Firstname"){
			valueFrm.firstname = event.target.value
		} else if (event?.target?.name === "Lastname"){
			valueFrm.lastname = event.target.value
		} else if (event?.target?.name === "Email"){
			valueFrm.email = event.target.value
		} else if (event?.target?.name === "Organisation"){
			valueFrm.organisation = event.target.value
		} else if (event?.target?.name === "Country"){
			valueFrm.country = event.target.value
		} else if (event?.target?.name === "Comments"){
			valueFrm.comment = event.target.value
		}
        alert("fname - "+valueFrm.firstname+", lname - "
            +valueFrm.lastname+", email - "+valueFrm.email+", phone - "+valueFrm.phone+", org - "+valueFrm.organisation+", contry - "+valueFrm.country+", captcha - "+isCaptchaValid);
		if (valueFrm.firstname !== "" &&  valueFrm.lastname !== "" &&  valueFrm.email !== ""
			&&  valueFrm.phone !== "" &&  valueFrm.organisation !== "" &&  valueFrm.country !== "" && isCaptchaValid) {
			document.getElementById("clickButton").disabled = false;

		} else {
			document.getElementById("clickButton").disabled = true;
		}*/
        setFormData(prev => ({
            ...prev,
            [name]: value
        }));
    };

    const btnclick = async (e) => {
        e.preventDefault();

        const requestData = {
            name: `${formData.firstname} ${formData.lastname}`,
            email: formData.email,
            phone: formData.phone,
            organisation: formData.organisation,
            country: formData.country,
            comment: formData.comment,
        };

        let bodytext = `<div className="application-container"><h1></h1><div className="applicant-info"><p><strong>Name:</strong> ${requestData.name} </p><p><strong>Email:</strong> ${requestData.email} </p><p><strong>Phone:</strong> ${requestData.phone} </p><p><strong>Organization:</strong> ${requestData.organisation} </p><p><strong>Country:</strong> ${requestData.country} </p></div><div className="comment"><h1>Comments</h1><p>${requestData.comment}</p></div></div>`;

        let emailData = {
            sender: page_json.contact_api.fromEmail,
            recipient: page_json.contact_api.toEmail,
            subject: `Enquiry for Contact Form Submission from page ${document.title}`,
            body_text: bodytext,
            body_html: `<html><body>${bodytext}</body></html>`,
        };

        const emailAPI = page_json.backend_apis.email_api;

        try {
            const response = await fetch(emailAPI, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(emailData),
            });

            if (response.ok) {
                const responseData = await response.json();
                console.log('Response:', responseData);

                alert('Query sent successfully');
                window.location.reload();
                //setFormSubmitted(true); 
            } else {
                console.error('Error:', response.statusText);
                alert('Query failed, try again!');
            }
        } catch (error) {
            console.error('Exception:', error);
            alert('An error occurred, please try again!');
        }
    };

    return (
        <div data-aos="fade-up">
            <div className="container-fluid contact-form">
                <div className="container">
                    <h1>{textData?.layer4?.heading}</h1>
                    <h4>{textData?.layer4?.body?.text1}</h4>
                    <form onSubmit={btnclick}>
                        <div className="row">
                            <div className="col-lg-4 col-md-4 col-sm-4 col-xs-12">
                                <div className="success1">
                                    <input type="text" value={formData.firstname} onChange={handleInputChange} className="form-control" name="firstname" id="FirstName" required placeholder={textData?.layer4?.body?.text2} />
                                </div>
                            </div>
                            <div className="col-lg-4 col-md-4 col-sm-4 col-xs-12">
                                <div className="success1">
                                    <input type="text" value={formData.lastname} onChange={handleInputChange} className="form-control" name="lastname" id="LastName" required placeholder={textData?.layer4?.body?.text3} />
                                </div>
                            </div>
                            <div className="col-lg-4 col-md-4 col-sm-4 col-xs-12">
                                <div className="success1">
                                    <input type="email" value={formData.email} onChange={handleInputChange} className="form-control" name="email" id="Email" required placeholder={textData?.layer4?.body?.text4} />
                                </div>
                            </div>
                            <div className="col-lg-4 col-md-4 col-sm-4 col-xs-12">
                                <div className="success1">
                                    <input type="number" maxLength="10" className="form-control" value={formData.phone} onChange={handleInputChange} name="phone" id="Phone" title="Only numbers" pattern="[0-9]{10}" required placeholder={textData?.layer4?.body?.text6} />
                                    {message && (
                                        <p style={{ color: 'red' }}>{message}</p>
                                    )}
                                </div>
                            </div>
                            <div className="col-lg-4 col-md-4 col-sm-4 col-xs-12">
                                <div className="success1">
                                    <input type="text" value={formData.organisation} onChange={handleInputChange} className="form-control" name="organisation" id="Organisation" required placeholder={textData?.layer4?.body?.text5} />
                                </div>
                            </div>
                            <div className="col-lg-4 col-md-4 col-sm-4 col-xs-12">
                                <div className="success1">
                                    <select id="Country" name="country" className="form-control" onChange={handleInputChange} required>
                                        <option value="">{textData?.layer4?.body?.text7}</option>
                                        {
                                            countryList.map((item) => (
                                                <option key={item.name} value={item.name}>{item.name}</option>
                                            ))
                                        }
                                    </select>
                                </div>
                            </div>
                            <div className="col-lg-6 col-md-6 col-sm-6 col-xs-6">
                                <div className="success1">
                                    <textarea className="form-control" value={formData.comment} onChange={handleInputChange} name="comment" id="Comments" rows="3" placeholder={textData?.layer4?.body?.text9}></textarea>
                                </div>
                            </div>
                            <div className="col-lg-6 col-md-6 col-sm-6 col-xs-6 recaptchadiv">
                                <Recaptcha setIsCaptchaValid={setIsCaptchaValid} />
                            </div>
                            <button type="submit" id="clickButton" className="btn" disabled={isButtonDisabled}>{textData?.layer4?.body?.text10}</button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    )
}

export default ContactForm;
