
import React, { useEffect } from 'react';
import AOS from 'aos';
// import logo from './content/public/images/client_Logos.webp';
// import value from './content/public/images/Boosting-value-with-effectivenrss.webp';
import page_json from './config/config.json';
import ContactForm from './ContactForm';
import { Helmet } from 'react-helmet';

const textDataUrl = page_json.page_jsons.Salesforce;
const textDataResponse = await fetch(textDataUrl);
const textData = await textDataResponse.json();

function Salesforce() {
    useEffect(() => {
        AOS.init(); // Initialize AOS
    }, []);

    return (
        <div data-aos="fade-up">
            <Helmet>
                <meta name="description" content="Discover the power of strategic collaboration EM leads the way with Salesforce" />
                <title>Salesforce | Technologies | Engineersmind</title>
            </Helmet>
            <div className="container-fluid m-0 p-0 brd-crmb">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                            <nav aria-label="breadcrumb">
                                <ol className="breadcrumb">
                                    <li className="breadcrumb-item"><a href="/">Home </a></li>
                                    <li className="breadcrumb-item"><a href="/Technologies/Salesforce">Technologies </a></li>
                                    <li className="breadcrumb-item active" aria-current="page">Salesforce</li>
                                </ol>
                            </nav>
                        </div>
                    </div>
                </div>
            </div>

            <div className="container-fluid p-0">
                <div className="inner-banner">
                    <img src={textData.layer1.img} className="d-block w-100" alt="banner-img" />
                    <div className="inner-banner-text">
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-8 col-md-8 col-sm-8 col-xs-8">
                                    <h1 className="animate__animated animate__fadeInLeft">{textData.layer1.heading.text1}<span>{textData.layer1.heading.text2}</span> {textData.layer1.heading.text3}<br /> <span>{textData.layer1.heading.text4}</span> {textData.layer1.heading.text5}</h1>
                                    <p>{textData.layer1.body}</p><br />

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="container-fluid">
                <div className="row">
                    <div className="col-lg-6 col-md-6 col-sm-12 col-xs-6">
                        <div className="boosting">
                            <h1>{textData.layer2.heading}</h1>
                            <p>{textData.layer2.body}</p>

                        </div>
                    </div>
                    <div className="col-lg-6 col-md-6 col-sm-12 col-xs-6">
                        <div className="boosting-img">
                            <img src={textData.layer2.img} className="d-block w-100" alt="banner-img" />
                        </div>
                    </div>
                </div>
            </div>

            <div className="container-fluid sales-offer">
                <div className="container">
                    <h1>{textData.layer3.heading.text1}<span> {textData.layer3.heading.text2}</span></h1>
                    <p>{textData.layer3.body}</p>
                    <div className="row">
                        <div className="col-lg-4 col-md-4 col-sm-12 col-xs-12">
                            <div className="module-border-wrap">
                                <div className="module">
                                    <p id="pdng">{textData.layer3.data.module1}</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-4 col-sm-12 col-xs-12">
                            <div className="module-border-wrap">
                                <div className="module">
                                    <p id="pdng">{textData.layer3.data.module2}</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-4 col-sm-12 col-xs-12">
                            <div className="module-border-wrap">
                                <div className="module">
                                    <p id="pdng">{textData.layer3.data.module3}</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-4 col-sm-12 col-xs-12">
                            <div className="module-border-wrap">
                                <div className="module">
                                    <p id="pdng">{textData.layer3.data.module4}</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-4 col-sm-12 col-xs-12">
                            <div className="module-border-wrap">
                                <div className="module">
                                    <p id="pdng">{textData.layer3.data.module5}</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-4 col-sm-12 col-xs-12">
                            <div className="module-border-wrap">
                                <div className="module">
                                    <p id="pdng">{textData.layer3.data.module6}</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-4 col-sm-12 col-xs-12">
                            <div className="module-border-wrap">
                                <div className="module">
                                    <p id="pdng">{textData.layer3.data.module7}</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-4 col-sm-12 col-xs-12">
                            <div className="module-border-wrap">
                                <div className="module">
                                    <p id="pdng">{textData.layer3.data.module8}</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-4 col-sm-12 col-xs-12">
                            <div className="module-border-wrap">
                                <div className="module">
                                    <p id="pdng">{textData.layer3.data.module9}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="container-fluid trust">
                <div className="container trusted-img">
                    <div className="row">
                        <h1>{textData.layer4.heading}</h1>
                        <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                            <img src={textData.layer4.img} alt="logos" className="img-fluid" />

                        </div>
                    </div>
                </div>
            </div>

            <div className="container-fluid alliance">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-7 col-md-6 col-sm-12 col-xs-12">
                            <div className="heading">
                                <h1><span>{textData.layer5.heading.text1}</span>{textData.layer5.heading.text2}<span>{textData.layer5.heading.text3}</span></h1>
                            </div>
                            <div className="more-2">
                                <a href={textData.layer5.button.url}>{textData.layer5.button.text} <i className="fa-solid fa-arrow-right"></i></a>
                            </div>
                        </div>
                        <div className="col-lg-5 col-md-6 col-sm-12 col-xs-12">
                            <div className="gif">
                                <img src={textData.layer5.button.img} alt="gif" className="img-fluid" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="container-fluid ContactForm">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                            <ContactForm />
                        </div>
                    </div>
                </div>
            </div>
            <div className="container-fluid tabg alliance">
                <div className="TA-bg">
                    <div className="container">
                        <div className="row">
                            <h1>{textData.layer6.heading}</h1>
                            <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                <img src={textData.layer6.img} alt="logos" className="img-fluid" />

                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    )
}
export default Salesforce;