
import React, { useEffect } from 'react';
import AOS from 'aos';
import page_json from './config/config.json'
import { Helmet } from 'react-helmet';
const textDataUrl = page_json.page_jsons.PrivacyPolicy;
const textDataResponse = await fetch(textDataUrl);
const textData = await textDataResponse.json();

function PrivacyPolicy() {
    useEffect(() => {
        AOS.init(); // Initialize AOS
    }, []);

    return (
        <div data-aos="fade-up">
            <Helmet>
                <meta name="robots" content="noindex, nofollow" />
                <meta name="description" content="Engineersmind Privacy Policy" />
                <title>Privacy Policy | Engineersmind</title>
            </Helmet>
            <div className="container-fluid SS">
                <div className="container">
                    <h1 data-aos="fade-down" data-aos-duration="1000">{textData.layer1.heading}</h1>
                    <div className="row">
                        <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                            <p><b>{textData.layer2.heading}</b></p>
                            <p>{textData.layer2.body.text1}</p>
                            <p>{textData.layer2.body.text2}<a href={textData.layer2.body.link} className="policy-link">{textData.layer2.body.link}</a></p>
                            <p>{textData.layer2.body.text3}</p>
                            <ul>
                                <li>{textData.layer2.data.point1}</li>
                                <li>{textData.layer2.data.point2}</li>
                            </ul>
                            <p>{textData.layer3.body.text1}</p>
                            <p>{textData.layer3.body.text2}</p>
                        </div>
                        <div className="col-lg-6 col-md-6 col-sm-6 col-xs-6">
                            <ol>
                                <li><p>{textData.layer3.data.point1}</p></li>
                                <li><p>{textData.layer3.data.point2}</p></li>
                                <li><p>{textData.layer3.data.point3}</p></li>
                                <li><p>{textData.layer3.data.point4}</p></li>
                                <li><p>{textData.layer3.data.point5}</p></li>
                                <li><p>{textData.layer3.data.point6}</p></li>
                            </ol>
                        </div>
                        <div className="col-lg-6 col-md-6 col-sm-6 col-xs-6">
                            <ol>
                                <li><p>{textData.layer3.data.point7}</p></li>
                                <li><p>{textData.layer3.data.point8}</p></li>
                                <li><p>{textData.layer3.data.point9}</p></li>
                                <li><p>{textData.layer3.data.point10}</p></li>
                                <li><p>{textData.layer3.data.point11}</p></li>
                            </ol>
                        </div>
                        <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                            <p>{textData.layer4.body.text1}</p>
                            <p>{textData.layer4.body.text2}</p>
                            <p>{textData.layer4.body.text3}</p>
                            <p>{textData.layer4.body.text4}</p>
                            <p>{textData.layer4.body.text5}</p>
                            <p>{textData.layer4.body.text6}</p>
                            <p>{textData.layer4.body.text7}</p>
                            <p>{textData.layer4.body.text8}</p>
                            <p>{textData.layer4.body.text9}</p>
                            <p>{textData.layer4.body.text10}</p>
                            <p>{textData.layer4.body.text11}</p>
                            <p>{textData.layer4.body.text12}</p>
                            <ul>
                                <li>{textData.layer4.data.point1}</li>
                                <li>{textData.layer4.data.point2}</li>
                                <li>{textData.layer4.data.point3}</li>
                            </ul>

                            <p>{textData.layer5.body.text1}</p>
                            <p>{textData.layer5.body.text2}</p>
                            <p>{textData.layer5.body.text3}</p>&nbsp;

                            <p><b>{textData.layer6.body.text1}</b></p>
                            <p>{textData.layer6.body.text2}</p>
                            <p>{textData.layer6.body.text3}</p>
                            <p>{textData.layer6.body.text4}</p>
                            <ul>
                                <li>{textData.layer6.data.point1}</li>
                                <li>{textData.layer6.data.point2}</li>
                                <li>{textData.layer6.data.point3}</li>
                                <li>{textData.layer6.data.point4}</li>
                                <li>{textData.layer6.data.point5}</li>
                                <li>{textData.layer6.data.point6}</li>
                                <li>{textData.layer6.data.point7}</li>
                                <li>{textData.layer6.data.point8}</li>
                                <li>{textData.layer6.data.point9}</li>
                                <li>{textData.layer6.data.point10}</li>
                                <li>{textData.layer6.data.point11}</li>
                                <li>{textData.layer6.data.point12}</li>
                                <li>{textData.layer6.data.point13}</li>
                                <li>{textData.layer6.data.point14}</li>
                                <li>{textData.layer6.data.point15}</li>
                            </ul>

                            <p><b>{textData.layer7.body.text1}</b></p>
                            <p>{textData.layer7.body.text2}</p>
                            <p>{textData.layer7.body.text3}</p>
                            <ul>
                                <li>{textData.layer7.data.point1}</li>
                                <li>{textData.layer7.data.point2}</li>
                                <li>{textData.layer7.data.point3}</li>
                                <li>{textData.layer7.data.point4}</li>
                                <li>{textData.layer7.data.point5}</li>
                                <li>{textData.layer7.data.point6}</li>
                                <li>{textData.layer7.data.point7}</li>
                            </ul>

                            <p><b>{textData.layer8.body.text1}</b></p>
                            <p>{textData.layer8.body.text2}</p>
                            <p>{textData.layer8.body.text3}</p>

                            <p><b>{textData.layer9.body.text1}</b></p>
                            <p>{textData.layer9.body.text2}</p>
                            <p>{textData.layer9.body.text3}</p>
                            <p>{textData.layer9.body.text4}</p>
                            <br/>
                            <p><b>{textData.layer10.body.text1}</b></p>
                            <p>{textData.layer10.body.text2}</p>
                            <p>{textData.layer10.body.text3}</p>
                            <br/>
                            <p><b>{textData.layer11.body.text1}</b></p>
                            <p>{textData.layer11.body.text2}</p>
                            <p>{textData.layer11.body.text3}<a href={textData.layer11.body.link3} className="policy-link">{textData.layer11.body.link3}</a>.</p>
                            <p>{textData.layer11.body.text4}<a href={textData.layer11.body.link4} className="policy-link">{textData.layer11.body.link4}</a>.</p>
                            <p>{textData.layer11.body.text5}<a href={textData.layer11.body.link5} className="policy-link">{textData.layer11.body.link5}</a>.</p>
                            <br/>
                            <p><b>{textData.layer12.body.text1}</b></p>
                            <p>{textData.layer12.body.text2}</p>
                            <br/>
                            <p><b>{textData.layer13.body1.text1}</b></p>
                            <p>{textData.layer13.body1.text2}</p>
                            <p>{textData.layer13.body1.text3}</p>
                            <p>{textData.layer13.body1.text4}</p>
                            <p>{textData.layer13.body1.text5}</p>
                            <p>{textData.layer13.body1.text6}</p>
                            <p>{textData.layer13.body1.text7}</p>
                            <p>{textData.layer13.body1.text8}</p>
                            <p>{textData.layer13.body1.text9}</p>
                            <p>{textData.layer13.body1.text10}</p>
                            <p>{textData.layer13.body1.text11}</p>
                            <p>{textData.layer13.body1.text12}</p>
                            <p>{textData.layer13.body1.text13}</p>
                            <p>{textData.layer13.body1.text14}</p>
                            <p>{textData.layer13.body1.text15}</p>
                            <p>{textData.layer13.body1.text16}</p>
                            <p>{textData.layer13.body1.text17}</p>
                            <p>{textData.layer13.body1.text18}</p>
                            <p>{textData.layer13.body1.text19}</p>
                            <p>{textData.layer13.body1.text20}</p>
                            <p>{textData.layer13.body1.text21}</p>
                            <p>{textData.layer13.body1.text22}</p>
                            <p>{textData.layer13.body1.text23}</p>
                            <p>{textData.layer13.body1.text24}</p>
                            <p>{textData.layer13.body1.text25}</p>
                            <ul>
                                <li>{textData.layer13.data1.point1}</li>
                                <li>{textData.layer13.data1.point2}</li>
                                <li>{textData.layer13.data1.point3}</li>
                            </ul>
                            <p>{textData.layer13.body2.text1}</p>
                            <p>{textData.layer13.body2.text2}</p>
                            <p>{textData.layer13.body2.text3_1}<a href={textData.layer13.body2.link3} className="policy-link">{textData.layer13.body2.link3}</a>{textData.layer13.body2.text3_2}</p>
                            <br/>
                            <p>{textData.layer13.body2.text4}</p>
                            <p>{textData.layer13.body2.text5}</p>
                            <p>{textData.layer13.body2.text6}</p>
                            <p>{textData.layer13.body2.text7}</p>
                            <p>{textData.layer13.body2.text8}</p>
                            <p>{textData.layer13.body2.text9}</p>
                            <p>{textData.layer13.body2.text10}</p>
                            <p>{textData.layer13.body2.text11}</p>
                            <p>{textData.layer13.body2.text12}</p>
                            <p>{textData.layer13.body2.text13}</p>
                            <ul>
                                <li>{textData.layer13.data2.point1}</li>
                                <li>{textData.layer13.data2.point2}</li>
                                <li>{textData.layer13.data2.point3}</li>
                                <li>{textData.layer13.data2.point4}</li>
                                <li>{textData.layer13.data2.point5}</li>
                                <li>{textData.layer13.data2.point6}</li>
                                <li>{textData.layer13.data2.point7}</li>
                            </ul>
                            <p>{textData.layer13.body3.text1}</p>
                            <p>{textData.layer13.body3.text2}</p>
                            <p>{textData.layer13.body3.text3}</p>
                            <p>{textData.layer13.body3.text4}</p>
                            <p>{textData.layer13.body3.text5}</p>
                            <p>{textData.layer13.body3.text6}</p>
                            <ul>
                                <li>{textData.layer13.data3.point1}</li>
                                <li>{textData.layer13.data3.point2}</li>
                                <li>{textData.layer13.data3.point3}</li>
                                <li>{textData.layer13.data3.point4}</li>
                            </ul>

                            <p><b>{textData.layer14.body.text1}</b></p>
                            <p>{textData.layer14.body.text2}</p>
                            <p>{textData.layer14.body.text3}</p>
                            <br/>
                            <p><b>{textData.layer15.body.text1}</b></p>
                            <p>{textData.layer15.body.text2}</p>
                            <p>{textData.layer15.body.text3}</p>
                            <br/>
                            <p><b>{textData.layer16.body.text1}</b></p>
                            <p>{textData.layer16.body.text2}</p>

                        </div>
                    </div>
                </div>
            </div>

            <div className="container-fluid alliance">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-7 col-md-6 col-sm-12 col-xs-12">
                            <div className="heading">
                                <h1><span>{textData.layer17.heading.text1}</span>{textData.layer17.heading.text2}<span>{textData.layer17.heading.text3}</span></h1>
                            </div>
                            <div className="more-2">
                                <a href={textData.layer17.button.url}>{textData.layer17.button.text}<i className="fa-solid fa-arrow-right"></i></a>
                            </div>
                        </div>
                        <div className="col-lg-5 col-md-6 col-sm-12 col-xs-12">
                            <div className="gif">
                                <img src={textData.layer17.button.img} alt="gif" className="img-fluid" />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="TA-bg">
                    <div className="container">
                        <div className="row">
                            <h1>{textData.layer18.heading}</h1>
                            <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                <img src={textData.layer18.img} alt="logos" className="img-fluid" />

                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    )
}

export default PrivacyPolicy;