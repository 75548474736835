
import React, { useEffect } from 'react';
import AOS from 'aos';
import page_json from './config/config.json';
import ContactForm from './ContactForm';
import { Helmet } from 'react-helmet';

const textDataUrl = page_json.page_jsons.Alliances;
const textDataResponse = await fetch(textDataUrl);
const textData = await textDataResponse.json();

function Alliances() {
	useEffect(() => {
		AOS.init(); // Initialize AOS
	}, []);

	return (
		<div data-aos="fade-up">
			<Helmet>
                <meta name="description" content="Liferay, AWS, Microsoft, Snowflake, Microsoft Managed Services" />
                <title>Alliances | About | Engineersmind</title>
            </Helmet>
			<div className="container-fluid m-0 p-0 brd-crmb">
				<div className="container">
					<div className="row">
						<div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
							<nav aria-label="breadcrumb">
								<ol className="breadcrumb">
									<li className="breadcrumb-item"><a href="/">Home </a></li>
									<li className="breadcrumb-item"><a href="/About">About Us</a></li>
									<li className="breadcrumb-item active" aria-current="page">Alliances</li>
								</ol>
							</nav>
						</div>
					</div>
				</div>
			</div>

			<div className="container-fluid alliances">
				<div className="container">
					<h1><span>{textData.layer1.heading}</span></h1>
					<div className="row">
						<div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
							<p>{textData.layer1.body.text1}</p>
							<p>{textData.layer1.body.text2}</p>&nbsp;
							<p>{textData.layer1.body.text3}</p>
						</div>
					</div>
				</div>
			</div>

			<div className="container-fluid">
				<div className="container">
					<div className="row">
						<div className="col-lg-4 col-md-4 col-sm-12 col-xs-12">
							<div className="tech-alliances">
								<img src={textData.layer2.details.detail1.img} alt="gif" className="img-fluid" />
							</div>
						</div>
						<div className="col-lg-8 col-md-8 col-sm-12 col-xs-12">
							<div className="tech-text">
								<h4>{textData.layer2.details.detail1.heading}</h4>
								<p>{textData.layer2.details.detail1.body1.text1}</p>
								<br />
								<p>{textData.layer2.details.detail1.body1.text2}</p>
								<p>{textData.layer2.details.detail1.body1.text3}</p>
								<br />
								<p>{textData.layer2.details.detail1.body1.text4}</p>
								<p>{textData.layer2.details.detail1.body1.text5}</p>
								<br />
								<p>{textData.layer2.details.detail1.data.body}</p>
								<ul className="alliance-ul">
									<li>{textData.layer2.details.detail1.data.subpoints.subpoint1}</li>
									<li>{textData.layer2.details.detail1.data.subpoints.subpoint2}</li>
									<li>{textData.layer2.details.detail1.data.subpoints.subpoint3}</li>
									<li>{textData.layer2.details.detail1.data.subpoints.subpoint4}</li>
								</ul>
								<br />
								<p>{textData.layer2.details.detail1.body2.text} <a href={textData.layer2.details.detail1.body2.button.url}>{textData.layer2.details.detail1.body2.button.text}</a></p>
							</div>
						</div>
						<div className="col-lg-4 col-md-4 col-sm-12 col-xs-12">
							<div className="tech-alliances">
								<img src={textData.layer2.details.detail2.img} alt="gif" className="img-fluid" />
							</div>
						</div>
						<div className="col-lg-8 col-md-8 col-sm-12 col-xs-12">
							<div className="tech-text">
								<h4>{textData.layer2.details.detail2.heading}</h4>
								<p>{textData.layer2.details.detail2.body1.text1}</p>
								<br />
								<p>{textData.layer2.details.detail2.data.body}</p>
								<ul className="alliance-ul">
									<li>{textData.layer2.details.detail2.data.subpoints.subpoint1}<a href={textData.layer2.details.detail2.data.subpoints.subpoint1_link}>{textData.layer2.details.detail2.data.subpoints.subpoint1_text}</a></li>
									<li>{textData.layer2.details.detail2.data.subpoints.subpoint2}<a href={textData.layer2.details.detail2.data.subpoints.subpoint2_link}>{textData.layer2.details.detail2.data.subpoints.subpoint2_text}</a></li>
								</ul>
								<br />
								<p><a href={textData.layer2.details.detail2.body2.button.url}>{textData.layer2.details.detail2.body2.button.text}</a>{textData.layer2.details.detail2.body2.text}</p>
							</div>
						</div>
						<div className="col-lg-4 col-md-4 col-sm-12 col-xs-12">
							<div className="tech-alliances">
								<img src={textData.layer2.details.detail3.img} alt="gif" className="img-fluid" />
							</div>
						</div>
						<div className="col-lg-8 col-md-8 col-sm-12 col-xs-12">
							<div className="tech-text">
								<h4>{textData.layer2.details.detail3.heading}</h4>
								<p>{textData.layer2.details.detail3.body1.text1}</p>
								<p>{textData.layer2.details.detail3.data.body}</p>
								<ul className="alliance-ul">
									<li>{textData.layer2.details.detail3.data.subpoints.subpoint1}</li>
									<li>{textData.layer2.details.detail3.data.subpoints.subpoint2}</li>
									<li>{textData.layer2.details.detail3.data.subpoints.subpoint3}</li>
									<li>{textData.layer2.details.detail3.data.subpoints.subpoint4}</li>
								</ul>
								<br />
								<p>{textData.layer2.details.detail3.body2.text} <a href={textData.layer2.details.detail3.body2.button.url}>{textData.layer2.details.detail3.body2.button.text}</a></p>
							</div>
						</div>
						<div className="col-lg-4 col-md-4 col-sm-12 col-xs-12">
							<div className="tech-alliances">
								<img src={textData.layer2.details.detail4.img} alt="gif" className="img-fluid" />
							</div>
						</div>
						<div className="col-lg-8 col-md-8 col-sm-12 col-xs-12">
							<div className="tech-text">
								<h4>{textData.layer2.details.detail4.heading}</h4>
								<p>{textData.layer2.details.detail4.body1.text1}</p>
								<p>{textData.layer2.details.detail4.body1.text2}</p>
								<br />
								<p>{textData.layer2.details.detail4.body2.text} <a href={textData.layer2.details.detail4.body2.button.url}>{textData.layer2.details.detail4.body2.button.text}</a></p>
							</div>
						</div>
						<div className="col-lg-4 col-md-4 col-sm-12 col-xs-12">
							<div className="tech-alliances">
								<img src={textData.layer2.details.detail5.img} alt="gif" className="img-fluid" />
							</div>
						</div>
						<div className="col-lg-8 col-md-8 col-sm-12 col-xs-12">
							<div className="tech-text">
								<h4>{textData.layer2.details.detail5.heading}</h4>
								<p>{textData.layer2.details.detail5.body1.text1}</p>
								<p>{textData.layer2.details.detail5.body1.text2}</p>
								<br />
								<p><a href={textData.layer2.details.detail5.body2.button.url}>{textData.layer2.details.detail5.body2.button.text}</a>{textData.layer2.details.detail5.body2.text} </p>
								<br />
							</div>
						</div>
					</div>
				</div>
			</div>

			<div className="container-fluid alliance">
				<div className="container">
					<div className="row">
						<div className="col-lg-7 col-md-6 col-sm-12 col-xs-12">
							<div className="heading">
								<h1><span>{textData.layer3.heading.text1}</span>{textData.layer3.heading.text2}<span>{textData.layer3.heading.text3}</span></h1>
							</div>
							<div className="more-2">
								<a href={textData.layer3.button.url}>{textData.layer3.button.text}<i className="fa-solid fa-arrow-right"></i></a>
							</div>
						</div>
						<div className="col-lg-5 col-md-6 col-sm-12 col-xs-12">
							<div className="gif">
								<img src={textData.layer3.button.img} alt="gif" className="img-fluid" />
							</div>
						</div>
					</div>
				</div>
			</div>
            <div className="container-fluid ContactForm">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                            <ContactForm />
                        </div>
                    </div>
                </div>
            </div>
            <div className="container-fluid tabg alliance">
				<div className="TA-bg">
					<div className="container">
						<div className="row">
							<h1>{textData.layer4.heading}</h1>
							<div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
								<img src={textData.layer4.img} alt="logos" className="img-fluid" />

							</div>
						</div>
					</div>
				</div>
			</div>

		</div>
	)
}

export default Alliances;