import React, { useEffect, useState } from 'react';
import AOS from 'aos';
import AWS from 'aws-sdk';
import Uploader from '../components/Uploader';
import { Hourglass } from 'react-loader-spinner';
import { useNavigate } from "react-router-dom";
import page_json from './config/config.json'

const region = process.env.REACT_APP_AWS_REGION;
const accessKeyID = process.env.REACT_APP_AWS_ACCESS_KEY_ID;
const secretAccessKey = process.env.REACT_APP_AWS_SECRET_ACCESS_KEY;
const mainBucket = process.env.REACT_APP_AWS_S3_MAIN_BUCKET;
const tempBucket = process.env.REACT_APP_AWS_S3_TEMP_BUCKET;

const deleteAPI = page_json.backend_apis.delete_api;

AWS.config.update({
	region: region,
	credentials: new AWS.Credentials(accessKeyID, secretAccessKey)
});

const s3 = new AWS.S3();

async function refreshFileList(setContent) {
	try {
		const mainData = await s3.listObjectsV2({ Bucket: mainBucket }).promise();
		const tempData = await s3.listObjectsV2({ Bucket: tempBucket }).promise();

		const content = new Map();

		mainData.Contents.forEach(object => {
			const signedUrl = s3.getSignedUrl('getObject', {
				Bucket: mainBucket,
				Key: object.Key
			});
			content.set(object.Key, { var1: signedUrl, var2: null });
		});

		tempData.Contents.forEach(object => {
			const signedUrl = s3.getSignedUrl('getObject', {
				Bucket: tempBucket,
				Key: object.Key
			});
			const existingEntry = content.get(object.Key) || {};
			existingEntry.var2 = signedUrl;
			content.set(object.Key, existingEntry);
		});

		setContent(content);
	} catch (error) {
		console.error('Error fetching file list:', error);
	}
}

function Editor() {
	const [content, setContent] = useState(new Map());
	let role = sessionStorage.getItem("role");
	const navigate = useNavigate();
	if (null === role) {
		navigate("/login");
	}

	useEffect(() => {
		AOS.init(); // Initialize AOS

		refreshFileList(setContent);

	}, []);

	const handleDownload = (url) => {
		// Perform download logic here, e.g., redirect or trigger download
		window.open(url, '_blank'); // Open in a new tab
	};

	const handleDelete = async (key) => {
		document.getElementsByClassName("tableComponent")[0].style.display = "none";
		document.getElementsByClassName("loader")[0].style.display = "flex";
		try {
			const sessionKey = sessionStorage.getItem("key");
			console.log(sessionKey);
			if (!sessionKey) {
				throw new Error('No key found in session');
			}
			await fetch(deleteAPI, {
				method: 'POST',
				headers: {
					'Content-Type': 'application/json'
				},
				body: JSON.stringify({ name: key, key: sessionKey })
			});

			// After successful deletion, refresh the file list
			refreshFileList(setContent);
			document.getElementsByClassName("tableComponent")[0].style.display = "flex";
			document.getElementsByClassName("loader")[0].style.display = "none";
		} catch (error) {
			console.error('Error deleting file:', error);
		}
	};

	return (
		<div data-aos="fade-up">
			<div className="container-fluid m-0 p-0">
				<h2>EDITOR PAGE</h2>
			</div>
			<div className="row">
				<div className="container-fluid m-0 p-0">
					<Uploader onUploadComplete={() => refreshFileList(setContent)} />
				</div>
			</div>
			<div className="container-fluid m-0 p-0">
				<h2>Uploaded Files</h2>
			</div>

			<div className="tableComponent">
				<table id="fileTable" className="table table-striped">
					<thead>
						<tr>
							<th>Name</th>
							<th>Website Content</th>
							<th>New Content</th>
							<th>Delete Content</th>
						</tr>
					</thead>
					<tbody id="contentTable">
						{Array.from(content).map(([key, value]) => (
							<tr key={key}>
								<td>{key}</td>
								<td>
									<button onClick={() => handleDownload(value.var1)} disabled={!value.var1}>Priview Old</button>
								</td>
								<td>
									<button onClick={() => handleDownload(value.var2)} disabled={!value.var2}>Priview New</button>
								</td>
								<td>
									<button onClick={() => handleDelete(key)} disabled={!value.var2}>Delete New</button>
								</td>
							</tr>
						))}
					</tbody>
				</table>
			</div>

			<div className="loader">
				<Hourglass
					height="80"
					width="80"
					ariaLabel="hourglass-loading"
					wrapperStyle={{}}
					wrapperClass=""
					colors={['#306cce', '#72a1ed']}
				/>
			</div>
			<div>
				<br /><br /><br /><br /><br />
			</div>
		</div>
	);
}

export default Editor;
