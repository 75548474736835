
import React, { useEffect, useState } from 'react';
import AOS from 'aos';
import page_json from './config/config.json'
//import AWS from 'aws-sdk';
//import { SES } from '@aws-sdk/client-ses';
import Recaptcha from './ReCaptcha';
import { Helmet } from 'react-helmet';

var textDataUrl = page_json.backend_apis.countries;
var textDataResponse = await fetch(textDataUrl);
const countryList = await textDataResponse.json();

textDataUrl = page_json.page_jsons.Contact;
textDataResponse = await fetch(textDataUrl);
const textData = await textDataResponse.json();

function Contact() {

	const [formData, setFormData] = useState({
		firstname: '',
		lastname: '',
		email: '',
		phone: '',
		city: '',
		country: '',
		comment: '',
		check1: false,
		check2: false,
	  });
	
	  const [isCaptchaValid, setIsCaptchaValid] = useState(false);
	  const [selectedOption, setSelectedOption] = useState('');
	  const [message, setMessage] = useState('');
	  const [isButtonDisabled, setIsButtonDisabled] = useState(true);


	useEffect(() => {
		AOS.init(); // Initialize AOS
	},[isCaptchaValid])
	
	useEffect(() => {
		// Enable/disable the button based on form validation
		//alert("firstname-"+formData.firstname+"---lastname - "+formData.lastname+"---email - "+formData.email+"---phone - "+formData.phone+"---city - "+formData.city+"---country - "+formData.country+"---selectedOption - "+selectedOption+"---check1 - "+formData.check1+"---isCaptchaValid - "+isCaptchaValid);
		const isValid = 
		  formData.firstname && formData.lastname && formData.email && formData.phone &&
		  formData.city && formData.country && selectedOption && formData.comment && formData.check1 && isCaptchaValid;
		
		setIsButtonDisabled(!isValid);
	  }, [formData, selectedOption, isCaptchaValid]);
	

	  const handleChange = (event) => {
		const { name, value, type, checked } = event.target;
	
		// Phone validation
		if (name === 'phone') {
		  const regex = /^[0-9()+-,]*$/;
		  if (value.length === 0 || regex.test(value)) {
			setMessage('');
		  } else {
			setMessage('Please enter a valid phone number.');
		  }
		}
		if (name === 'Information') {
			setSelectedOption(value);
		}	
		// Update form data dynamically
		setFormData((prevData) => ({
		  ...prevData,
		  [name]: type === 'checkbox' ? checked : value,
		}));
	
		
	  };

	const btnclick = async (e) => {
		e.preventDefault();
		
		// Prepare the data to be sent in the API request
		const requestData = {
		  name: `${formData.firstname} ${formData.lastname}`,
		  department: selectedOption,
		  email: formData.email,
		  phone: formData.phone,
		  city: formData.city,
		  country: formData.country,
		  comment: formData.comment,
		};
	  let bodytext = `<div className="application-container"><h1></h1><div className="applicant-info"><p><strong>Name:</strong> ${requestData.name} </p><p><strong>Email:</strong> ${requestData.name} </p><p><strong>Phone:</strong> ${formData.phone} </p><p><strong>City:</strong> ${formData.city} </p><p><strong>Country:</strong> ${formData.country} </p></div><div className="comment"><h1>Comments</h1><p>${formData.comment}</p></div></div>`;
	  let emailData={
		//sender: 'no-reply@em.engineersmind.com',
		sender: page_json.contact_api.fromEmail,
		recipient: page_json.contact_api.toEmail,
		subject: `Enquiry for ${selectedOption}`,
		body_text: bodytext,
		body_html: `<html><body>${bodytext}</body></html>`,
		//attachments: [{filename: 'example.txt', content: btoa('This is the content of the test file.')} ], // Array of objects with { filename: 'example.txt', content: 'base64string' }
	  };
		// Define the API endpoint
		const emailAPI = page_json.backend_apis.email_api;
		//const apiUrl = emailAPI;
	  console.log(emailData);
		try {
		  // Make the API call using fetch
		  const response = await fetch(emailAPI, {
			method: 'POST', // Use the appropriate HTTP method
			headers: {
			  'Content-Type': 'application/json', // Set the content type to JSON
			},
			body: JSON.stringify(emailData), // Convert the data to JSON
		  });
	  
		  // Check if the response is successful
		  if (response.ok) {
			// Optionally, parse the JSON response
			const responseData = await response.json();
			console.log('Response:', responseData);
			
			alert('Query sent successfully');
			window.location.reload(); // Reload the page on success
		  } else {
			// Handle errors
			console.error('Error:', response.statusText);
			alert('Query failed, try again!');
		  }
		} catch (error) {
		  // Handle exceptions
		  console.error('Exception:', error);
		  alert('An error occurred, please try again!');
		}
	  };
	
	return (
		<div data-aos="fade-up">
			<Helmet>
                <meta name="description" content="Engineersmind - Contact Us" />
                <title>Contact Us | Engineersmind</title>
            </Helmet>
			<div className="container-fluid m-0 p-0 brd-crmb" id="crumb-2">
				<div className="container">
					<div className="row">
						<div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
							<nav aria-label="breadcrumb">
								<ol className="breadcrumb">
									<li className="breadcrumb-item"><a href="/">Home </a></li>
									<li className="breadcrumb-item active" aria-current="page">Contact</li>
								</ol>
							</nav>
						</div>
					</div>
				</div>
			</div>

			<div className="container-fluid contact">
				<div className="container">
					<h1>{textData.layer1.heading}</h1>
					<p><span>{textData.layer1.body.text1}</span></p>
					<p>{textData.layer1.body.text2}</p>
					<p>{textData.layer1.body.text3}</p>
					<p>{textData.layer1.body.text4}</p>&nbsp;
					<p><a href={`mailto:${textData.layer1.body.text5}`}>{textData.layer1.body.text5}</a></p>
					<p><a href={`tel:${textData.layer1.body.text6.replace(/[\s-]/g, '')}`}>{textData.layer1.body.text6}</a></p>&nbsp;
					&nbsp;
					{/* <form id="myForm" action=" " method="post"> */}
					<form onSubmit={btnclick}> 
						<h1>{textData.layer2.heading}</h1>
						<div className="row">
							<div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
								<div className="success1">
									<select id="Information" name="Information" value={selectedOption} className="form-control" required onChange={handleChange}>
										<option value="" disabled>Select</option>
										<option value="Sales">Sales</option>
										<option value="Consulting">Consulting</option>
										<option value="HR">HR</option>
									</select>
									<label htmlFor="Information" className="form-label">{textData.layer2.body.text1}</label>
								</div>
							</div>
							<div className="col-lg-6 col-md-6 col-sm-6 col-xs-6">
								<div className="success1">
									<input type="text" value={formData.firstname} onChange={handleChange} className="form-control" name="firstname" id="firstName" required />
									<label htmlFor="firstname" className="form-label">{textData.layer2.body.text2}</label>
								</div>
							</div>
							<div className="col-lg-6 col-md-6 col-sm-6 col-xs-6">
								<div className="success1">
									<input type="text" value={formData.lastname} onChange={handleChange} className="form-control" name="lastname" id="lastname" required />
									<label htmlFor="lastname" className="form-label">{textData.layer2.body.text3}</label>
								</div>

							</div>
							<div className="col-lg-6 col-md-6 col-sm-6 col-xs-6">
								<div className="success1">
									<input type="email" value={formData.email} onChange={handleChange} className="form-control" name="email" id="email" required />
									<label htmlFor="email" className="form-label">{textData.layer2.body.text4}</label>
								</div>
							</div>
							<div className="col-lg-6 col-md-6 col-sm-6 col-xs-6">
								<div className="success1">
									<input type="number" maxLength="10" className="form-control" value={formData.phone} onChange={handleChange}  name="phone" id="phone" title="Only numbers" pattern="[0-9]{10}" required />
									<label htmlFor="phone" className="form-label">{textData.layer2.body.text5}</label>
									{message && (
										<p style={{ color: message === 'Mobile number is valid.' ? 'green' : 'red' }}>
										{message}
										</p>
									)}
								</div>
							</div>
							<div className="col-lg-6 col-md-6 col-sm-6 col-xs-6">
								<div className="success1">
									<input type="text" value={formData.city} onChange={handleChange}  className="form-control" name="city" id="city" required />
									<label htmlFor="city" className="form-label">{textData.layer2.body.text6}</label>
								</div>
							</div>
							<div className="col-lg-6 col-md-6 col-sm-6 col-xs-6">
								<div className="success1">
									<select id="country" name="country" className="form-control" onChange={handleChange} required>
										<option value="">{textData.layer2.body.text7}</option>
										{
											countryList.data.map(function(item){
												return(<option value={item.name}>{item.name}</option>)
											  })
										}
									</select>
									<label htmlFor="country" className="form-label">{textData.layer2.body.text8}</label>
								</div>
							</div>
							<div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
								<div className="success1">
									<textarea className="form-control" value={formData.comment} onChange={handleChange} name="comment" id="comment" rows="3"></textarea>
									<label htmlFor="comment" className="form-label">{textData.layer2.body.text9} </label>
								</div>
							</div>&nbsp;
							<div className="mb-12 form-check">
								<input type="checkbox"  value={formData.agree1} onClick={handleChange} name="check1" className="form-check-input" id="agree1" required />
								<label className="form-check-label" htmlFor="agree1">{textData.layer2.body.text10}</label>
							</div>
							<div className="mb-12 form-check">
								<input type="checkbox"  value={formData.agree2} onClick={handleChange} name="check2" className="form-check-input" id="agree2"/>
								<label className="form-check-label" htmlFor="agree2">{textData.layer2.body.text11}</label>
							</div>
							<div className="mb-12">
								<Recaptcha setIsCaptchaValid={setIsCaptchaValid}/>
							</div>
							<button type="submit" id="clickButton" className="btn" disabled={isButtonDisabled} >{textData.layer2.body.text12}</button>
						</div>
					</form>

				</div>
			</div>
			<div className="container-fluid loc-img">
				<div className="container">
					<h1>{textData.layer3.heading}</h1>
					<div className="row">
						{
							textData.layer3.editableList_locations.map(function(item){
								return(<div className="col-lg-3 col-md-3 col-sm-12 col-xs-12">
											<div className="location">
												<img src={item.img} alt="gif" className="img-fluid" />
												<p>{item.name}</p>
											</div>
										</div>)
							  })
						}
						
					</div>
				</div>
			</div>
		</div>
	)


}

export default Contact;



